import { useEffect, useState, useRef, Fragment } from 'react';
import { copyToClipboard } from '../../../../utils/Common';
import { useStateValue } from '../../../../StateProvider';

const GridMapV5 = ({
	gridData,
	placeId,
	placeCid,
	isFullScreen,
	isSinglePublicView = false,
	reportSettings,
	onMapGridLoaded = null,
	fixedHeight = null,
	isCompareReport = false
}) => {
	const [ mapId, setMapId ] = useState();
	const [ markers, setMarkers ] = useState();
	const [ targetId, setTargetId ] = useState();
	const [ mapHeight, setMapHeight ] = useState('');
	const [ mapDataNotAvailable, setMapDataNotAvailable ] = useState(false);
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(
		() => {
			if (gridData && (placeId || placeCid)) {
				if (isCompareReport) {
					//console.log('is compare report');
				}

				if (gridData.keyword && gridData.points && gridData.points.length) {
					setMapId(
						'report-map-' +
							gridData.keyword.toLowerCase().replaceAll(' ', '-') +
							'-' +
							Math.floor(Math.random() * 100)
					);

					clearMarkers();
					setTargetId(placeId ? placeId : placeCid);
					setMapDataNotAvailable(false);
				} else {
					setMapId();
					//setMapId('report-map-keyword' + '-' + Math.floor(Math.random() * 100));
					setMapDataNotAvailable(true);
					setTargetId(placeId ? placeId : placeCid);
				}
			} else {
				setMapId();
			}
		},
		[ gridData, placeId, placeCid, reportSettings ]
	);

	useEffect(
		() => {
			if (targetId && !markers) {
				if (mapId) {
					let map = document.getElementById(mapId);

					if (map) {
						// if (isCompareReport) {
						// 	console.log('mapId: ' + mapId);
						// }

						setMapHeight(fixedHeight ? `${fixedHeight}px` : map.offsetWidth);
					}

					initializeGrid();
				} else {
					setMapDataNotAvailable(true);
				}
			}
		},
		[ targetId, markers, mapId ]
	);

	const clearMarkers = () => {
		if (markers && markers.length > 0) {
			markers.forEach((marker) => {
				marker.map = null;
			});

			setMarkers();
		}
	};

	const initializeGrid = async () => {
		const center = { lat: gridData.lat, lng: gridData.lng };

		const keyword = gridData;

		// if (!keyword || !keyword.points) {
		// 	setMapDataNotAvailable(true);
		// 	return;
		// }

		const google = window.google;

		const map = new google.maps.Map(document.getElementById(mapId), {
			zoom   : 12,
			center : center,
			mapId  : mapId
		});

		if (mobileReportView) {
			map.setOptions({
				disableDefaultUI : true,
				// draggable        : false,
				zoomControl      : false
				// scrollwheel: false,
				// disableDoubleClickZoom: true
			});
		}

		let bounds = new google.maps.LatLngBounds();

		let tempMarkers = [];

		for (let i = 0; i < gridData.horizontalPoints; i++) {
			for (let j = 0; j < gridData.verticalPoints; j++) {
				const x = i + 1;
				const y = j + 1;

				const targetPoint = keyword.points.find((p) => p.pointXIndex == x && p.pointYIndex == y);

				if (targetPoint) {
					const targetPosition = {
						lat : targetPoint.lat,
						lng : targetPoint.lng
					};

					const markerView = new google.maps.marker.AdvancedMarkerView({
						map,
						position : {
							lat : targetPosition.lat,
							lng : targetPosition.lng
						},
						content  : buildContent(targetPoint, placeId, placeCid)
					});

					const element = markerView.element;

					[ 'focus', 'pointerenter' ].forEach((event) => {
						element.addEventListener(event, () => {
							highlight(markerView, null);
						});
					});
					[ 'blur', 'pointerleave' ].forEach((event) => {
						element.addEventListener(event, () => {
							unhighlight(markerView, null);
						});
					});
					[ 'click' ].forEach((event) => {
						element.addEventListener(event, () => {
							// let latLngValue = markerView.position.lat + ',' + markerView.position.lng;
							// copyToClipboard(latLngValue, 'Lat and long coordinates copied!');
							let mapUrl = `https://www.google.com/maps/search/${keyword.keyword}/@${markerView.position
								.lat},${markerView.position.lng},13z`;
							copyToClipboard(mapUrl, 'Map URL copied!');
						});
					});

					tempMarkers.push(markerView);

					bounds.extend(targetPosition);
				}
			}
		}

		map.fitBounds(bounds);

		setMarkers(tempMarkers);

		if (onMapGridLoaded) {
			onMapGridLoaded(true);
		}

		setTimeout(function() {
			if (!keyword || !keyword.points || keyword.points.length === 0) {
				setMapDataNotAvailable(true);
			}
		}, 200);
	};

	function highlight(markerView, property) {
		markerView.content.classList.add('highlight');
		markerView.element.style.zIndex = 1;
	}

	function unhighlight(markerView, property) {
		markerView.content.classList.remove('highlight');
		markerView.element.style.zIndex = '';
	}

	function buildContent(point, rankedPlaceId, rankedPlaceCid) {
		const content = document.createElement('div');

		content.classList.add('ranking-bubble');
		// first let's check if the place we're checking for is in the list of
		// results and assign the proper className
		let className = 'low';
		let position = '21+';
		let rankedPlace;
		let customStyle = '';

		if (point.results && point.results.length > 0) {
			//sort point results by position
			point.results.sort(function(a, b) {
				return parseInt(a.position) - parseInt(b.position);
			});
		}

		if (
			(rankedPlaceId && rankedPlaceId.trim() !== '') ||
			(rankedPlaceCid && rankedPlaceCid.trim() !== '' && point.results && point.results.length > 0)
		) {
			if (rankedPlaceId && rankedPlaceId.trim() !== '') {
				if (point.results && point.results.length > 0) {
					rankedPlace = point.results.find((p) => p.place_id && p.place_id.indexOf(rankedPlaceId) > -1);
				}
			} else if (!rankedPlace && rankedPlaceCid && rankedPlaceCid.trim() !== '') {
				rankedPlace = point.results.find((p) => p.data_cid && p.data_cid.indexOf(rankedPlaceCid) > -1);
			}
		}

		if (rankedPlace) {
			const rankedPosition = rankedPlace.position;
			position = rankedPosition;

			if (position <= 3) {
				className = 'excellent';

				if (reportSettings.mapMarkerTop3BackgroundColor) {
					customStyle += `background-color: ${reportSettings.mapMarkerTop3BackgroundColor}; `;
				}
				if (reportSettings.mapMarkerTop3TextColor) {
					customStyle += `color: ${reportSettings.mapMarkerTop3TextColor}; `;
				}
				if (reportSettings.mapMarkerTop3BorderColor) {
					customStyle += `border-color: ${reportSettings.mapMarkerTop3BorderColor}; `;
				}
			} else if (position <= 10) {
				className = 'decent';

				if (reportSettings.mapMarkerTop10BackgroundColor) {
					customStyle += `background-color: ${reportSettings.mapMarkerTop10BackgroundColor}; `;
				}
				if (reportSettings.mapMarkerTop10TextColor) {
					customStyle += `color: ${reportSettings.mapMarkerTop10TextColor}; `;
				}
				if (reportSettings.mapMarkerTop10BorderColor) {
					customStyle += `border-color: ${reportSettings.mapMarkerTop10BorderColor}; `;
				}
			} else if (position > 10 && position <= 20) {
				className = 'bad';

				if (reportSettings.mapMarkerTop20BackgroundColor) {
					customStyle += `background-color: ${reportSettings.mapMarkerTop20BackgroundColor}; `;
				}
				if (reportSettings.mapMarkerTop20TextColor) {
					customStyle += `color: ${reportSettings.mapMarkerTop20TextColor}; `;
				}
				if (reportSettings.mapMarkerTop20BorderColor) {
					customStyle += `border-color: ${reportSettings.mapMarkerTop20BorderColor}; `;
				}
			}

			if (position > 20) position = '21+';
		} else if (!point.results || point.results.length === 0) {
			className = 'not-found';
			content.innerHTML = `<div class="icon"><span class="value">NF</span></div>`;
			content.classList.add(className);

			return content;
		}

		// now append the class to the element
		content.classList.add(className);

		if (point.results) {
			// now let's build the rest of the content
			content.innerHTML = `<div class="icon"><span class="value"${customStyle !== ''
				? ` style="${customStyle}"`
				: ''}>${position}</span></div>`;

			const details = document.createElement('div');
			details.classList.add('details');

			const businessList = document.createElement('div');
			businessList.classList.add('business-list');

			for (let i = 0; i < point.results.length && i < 3; i++) {
				// loop first three results and add them to details
				const result = point.results[i];

				const business = document.createElement('div');
				business.classList.add('business-container');
				business.innerHTML = `
				<div class="ranking">${result.position}</div>
				<div class="name">${result.title}</div>
				<!--<div class="url"><a href="${result.website}" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>-->
			`;

				if (
					rankedPlace &&
					((result.place_id && result.place_id.indexOf(rankedPlace.place_id) > -1) ||
						(result.data_cid && result.data_cid.indexOf(rankedPlace.data_cid) > -1))
				) {
					business.classList.add('target');
				}

				businessList.appendChild(business);
			}

			if (point.results.length > 0) {
				details.appendChild(businessList);
				content.appendChild(details);
			}
		}

		if (point.improvement) {
			const improvement = document.createElement('div');
			improvement.classList.add('improvement');

			const improvementValue = point.improvement;
			let improvementClass = 'bad';
			let improvementIcon = '<i class="fa-solid fa-arrow-down"></i>';

			if (improvementValue >= 0) {
				improvementClass = 'good';
				improvementIcon = '<i class="fa-solid fa-arrow-up"></i>';
			}

			improvement.innerHTML = `<div class="improvement value ${improvementClass}">${improvementIcon}<span>${improvementValue
				.toString()
				.replace('-', '')}</span></div>`;

			content.querySelector('.details').appendChild(improvement);

			content.classList.add(`marker-improvement-${improvementClass}`);
		}

		return content;
	}

	return (
		<Fragment>
			{targetId && (
				<div
					className={`map ${isSinglePublicView ? 'single-public-view' : ''}`}
					id={mapId}
					style={!isFullScreen ? { height: mapHeight } : { height: '100%' }}
				>
					{mapDataNotAvailable && (
						<div className="map-data-not-available">
							<div className="box">No Data Available</div>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default GridMapV5;
