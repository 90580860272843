import { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Spinner from '../../components/spinner/Spinner';
import {
	AddDemoReport,
	GetCampaign,
	GetCampaignKeywords,
	GetCampaignReports,
	GetDemoReport,
	GetReportSettings,
	GetSEOReportKeywords,
	GetSEOReportV2,
	GetSEOReportWithSproc,
	MergeCampaignReportsIntoDb,
	UpdateReportSettings
} from '../../Services';
import { UnixTimestampToDateTime } from '../../utils/DateTime';
import './PublicViewCampaignReportScreenV5.css';
import { useStateValue } from '../../StateProvider';
import { copyToClipboard } from '../../utils/Common';
import ModalDialog from '../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import GridKeywordPickerArrowsV5 from '../../components/gridReport/v5/gridKeywordPicker/GridKeywordPickerArrowsV5';
import GridReportToggleTabsV5 from '../../components/gridReport/v5/gridReportToggleTabs/GridReportToggleTabsV5';
import KeywordMapStatsV4 from '../../components/gridReport/v4/keywordMapStats/KeywordMapStatsV4';
import { actionTypes } from '../../reducer';
import RankReportV5 from '../../components/gridReport/v5/rankReport/RankReportV5';
import GridMapV5 from '../../components/gridReport/v5/gridMap/GridMapV5';
import SendReportDialog from '../../components/gridReport/sendReportDialog/SendReportDialog';
import moment from 'moment';
import GenerateContentDialog from '../../components/gridReport/generateContentDialog/GenerateContentDialog';

const PublicViewCampaignReportScreenV5New = () => {
	const [ isFirstLoad, setIsFirstLoad ] = useState(false);
	const [ isLoadingCampaign, setIsLoadingCampaign ] = useState(true);
	const [ isLoadingReport, setIsLoadingReport ] = useState(true);
	const [ isLoadingRanking, setIsLoadingRanking ] = useState(true);
	const [ isSubmittingSales, setIsSubmittingSales ] = useState(false);
	const { campaignId, demoId } = useParams();
	const [ campaign, setCampaign ] = useState();
	const [ demoReport, setDemoReport ] = useState();
	const [ campaignReports, setCampaignReports ] = useState();
	const [ report, setReport ] = useState();
	const [ reportIdValue, setReportIdValue ] = useState();
	const [ activeKeyword, setActiveKeyword ] = useState();
	const [ targetKeyword, setTargetKeyword ] = useState();
	const [ compareToKeyword, setCompareToKeyword ] = useState();
	const [ selectedReportName, setSelectedReportName ] = useState('');
	const [ compareToReportId, setCompareToReportId ] = useState('');
	const [ compareToReport, setCompareToReport ] = useState();
	const [ compareToReportName, setCompareToReportName ] = useState('');
	const [ compareToReports, setCompareToReports ] = useState();
	//const [ mobileReportView, setMobileReportView ] = useState('base');
	const [ mapFullScreen, setMapFullScreen ] = useState(false);
	const [ isCampaignCanceled, setIsCampaignCanceled ] = useState(false);
	const [ isCampaignDeleted, setIsCampaignDeleted ] = useState(false);
	const [ noReports, setNoReports ] = useState(false);
	const [ queryParameters ] = useSearchParams();
	const [ queryParamStartDate, setQueryParamStartDate ] = useState();
	const [ queryParamEndDate, setQueryParamEndDate ] = useState();
	const [ queryParamKeyword, setQueryParamKeyword ] = useState();
	const [ keywordObjects, setKeywordObjects ] = useState();
	const [ { host, user, mobileReportView }, dispatch ] = useStateValue();
	const shareLinkRef = useRef();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const [ showSendReportDialog, setShowSendReportDialog ] = useState(false);
	const [ showGenerateContentDialog, setShowGenerateContentDialog ] = useState(false);
	const [ showReportSettingsDialog, setShowReportSettingsDialog ] = useState(false);
	const [ reportSettingsFormData, setReportSettingsFormData ] = useState({});
	const [ top3TotalPages, setTop3TotalPages ] = useState();
	const [ rankResponse, setRankResponse ] = useState();
	const [ compareToRankResponse, setCompareToRankResponse ] = useState();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	useEffect(() => {
		if (queryParameters) {
			setQueryParamStartDate(queryParameters.get('startDate'));
			setQueryParamEndDate(queryParameters.get('endDate'));
			setQueryParamKeyword(queryParameters.get('keyword'));
		}
	}, []);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign(campaignId);
				setIsFirstLoad(true);
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (demoId) {
				getDemoReport(demoId);
			}
		},
		[ demoId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getCampaignReports();
				getReportSettings();
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign && reportIdValue && !demoId) {
				getCampaignKeywords(reportIdValue);
			}
		},
		[ campaign, reportIdValue ]
	);

	useEffect(
		() => {
			if (isCampaignCanceled) {
				setIsLoadingCampaign(false);
			}
		},
		[ isCampaignCanceled ]
	);

	useEffect(
		() => {
			if (isCampaignDeleted) {
				setIsLoadingCampaign(false);
			}
		},
		[ isCampaignDeleted ]
	);

	useEffect(
		() => {
			if (noReports) {
				setIsLoadingCampaign(false);
			}
		},
		[ noReports ]
	);

	useEffect(
		() => {
			if (campaignReports && reportIdValue) {
				let currentReport = campaignReports.find((r) => r.id === reportIdValue);

				if (currentReport) {
					let tempCompareToReports = campaignReports.filter(
						(r) => r.id !== currentReport.id && r.dateUnix < currentReport.dateUnix
					);

					setCompareToReports(tempCompareToReports);

					if (!demoId) {
						//find the oldest report
						if (tempCompareToReports && tempCompareToReports.length > 0) {
							const oldestCompareToReport = tempCompareToReports.reduce(
								(r, o) => (o.dateCreated < r.dateCreated ? o : r)
							);

							if (queryParamStartDate) {
								setCompareToReportId(queryParamStartDate);
							} else {
								if (oldestCompareToReport) {
									setCompareToReportId(oldestCompareToReport.id);
									//getCompareToReport(oldestCompareToReport.id);
								} else {
									setCompareToReportId('0');
								}
							}
						} else {
							setCompareToReportId('0');
						}
					}
				} else {
					setCompareToReportId('0');
				}
			} else {
				setCompareToReports();
			}
		},
		[ reportIdValue ]
	);

	useEffect(
		() => {
			if (reportIdValue && keywordObjects && keywordObjects.length && !demoId)
				getReport(reportIdValue, compareToReportId && compareToReportId !== '0' ? compareToReportId : null);
		},
		[ reportIdValue, compareToReportId, keywordObjects ]
	);

	useEffect(
		() => {
			if (report && !demoId) {
				if (report.gridResponse && report.gridResponse.data && report.gridResponse.data.length > 0) {
					let currentKeywordData;

					if (queryParamKeyword) {
						currentKeywordData = report.gridResponse.data.find(
							(k) => k.keyword.toLowerCase() === queryParamKeyword.toLowerCase()
						);
					} else {
						currentKeywordData = report.gridResponse.data[0];
					}

					if (isFirstLoad) {
						setTargetKeyword({
							lat              : report.gridResponse.lat,
							lng              : report.gridResponse.lng,
							horizontalPoints : report.gridResponse.horizontalPoints,
							verticalPoints   : report.gridResponse.verticalPoints,
							...currentKeywordData
						});

						setActiveKeyword(currentKeywordData.keyword);
					}
				}

				if (!compareToReportId || compareToReportId === '0') {
					//setIsLoading(false);
					setIsLoadingReport(false);
				}

				setIsFirstLoad(false);
			}
		},
		[ report ]
	);

	useEffect(
		() => {
			if (activeKeyword && compareToReport && compareToReport.gridResponse) {
				let gridDataCompareTo = compareToReport.gridResponse;
				let selectedCompareToKeyword;

				if (compareToReport.gridResponse.data && compareToReport.gridResponse.data.length > 0) {
					selectedCompareToKeyword = gridDataCompareTo.data.find((k) => k.keyword === activeKeyword);
				}

				if (!selectedCompareToKeyword) {
					//set empty keyword to prevent code break
					selectedCompareToKeyword = {
						keyword : activeKeyword,
						points  : []
					};
				}

				setCompareToKeyword({
					lat              : gridDataCompareTo.lat,
					lng              : gridDataCompareTo.lng,
					horizontalPoints : gridDataCompareTo.horizontalPoints,
					verticalPoints   : gridDataCompareTo.verticalPoints,
					...selectedCompareToKeyword
				});

				//setIsLoading(false);
				setIsLoadingReport(false);
			}
		},
		[ activeKeyword, compareToReport ]
	);

	//set loading spinner for rank results
	useEffect(
		() => {
			let rankResultsLoaded = false;

			if (compareToReportId && compareToReportId !== '0') {
				if (rankResponse && compareToRankResponse) {
					rankResultsLoaded = true;
				}
			} else {
				if (rankResponse) {
					rankResultsLoaded = true;
				}
			}

			if (rankResultsLoaded) {
				setIsLoadingRanking(false);
			}
		},
		[ rankResponse, compareToRankResponse ]
	);

	//calculate top3 total pages count
	useEffect(
		() => {
			if (rankResponse) {
				//calculate top 3 total pages for all keywords in order to be used in comments text
				if (rankResponse && rankResponse.results && rankResponse.results.length > 0) {
					//remove possible duplicate locations
					let distinctRankData = rankResponse.results.reduce((accumulator, current) => {
						if (
							!accumulator.find(
								(item) => item.keyword === current.keyword && item.location === current.location
							)
						) {
							accumulator.push(current);
						}
						return accumulator;
					}, []);

					var top3PagesData = distinctRankData.filter((r) => r.ranking && r.ranking < 4);

					setTop3TotalPages(top3PagesData && top3PagesData.length ? top3PagesData.length : null);
				}
			}
		},
		[ rankResponse ]
	);

	//demo report
	useEffect(
		() => {
			if (demoReport) {
				let tempReportId = demoReport.endDate;

				getCampaignKeywords(tempReportId);
			}
		},
		[ demoReport ]
	);

	//demo report
	useEffect(
		() => {
			if (demoReport && keywordObjects && keywordObjects.length) {
				let tempReportId = demoReport.endDate;
				let tempCompareToReportId = demoReport.startDate;

				getReport(
					tempReportId,
					tempCompareToReportId && tempCompareToReportId !== '0' ? tempCompareToReportId : null
				);

				setReportIdValue(tempReportId);
				setCompareToReportId(tempCompareToReportId);
			}
		},
		[ demoReport, keywordObjects ]
	);

	//demo report
	useEffect(
		() => {
			if (
				demoReport &&
				report &&
				report.gridResponse &&
				report.gridResponse.data &&
				report.gridResponse.data.length > 0
			) {
				let currentKeywordData = report.gridResponse.data.find(
					(k) => k.keyword.toLowerCase() === demoReport.keyword.toLowerCase()
				);

				setTargetKeyword({
					lat              : report.gridResponse.lat,
					lng              : report.gridResponse.lng,
					horizontalPoints : report.gridResponse.horizontalPoints,
					verticalPoints   : report.gridResponse.verticalPoints,
					...currentKeywordData
				});

				setActiveKeyword(currentKeywordData.keyword);
			}
		},
		[ demoReport, report ]
	);

	const getCampaign = async (id) => {
		const response = await GetCampaign(id);

		if (response) {
			if (response.status === 'Canceled') {
				setIsCampaignCanceled(true);
			} else if (response.status === 'Deleted') {
				setIsCampaignDeleted(true);
			}

			setCampaign(response);

			//check if report should be merged into db
			if (response.mergedIntoDb === undefined || response.mergedIntoDb === null || !response.mergedIntoDb) {
				let mergeResponse = await MergeCampaignReportsIntoDb(response.id);

				if (mergeResponse.success) {
					//reload the page
					window.location.href = window.location.href;
				}
			}
		}

		setIsLoadingCampaign(false);
	};

	const getDemoReport = async (id) => {
		const response = await GetDemoReport(id);

		if (response) {
			setDemoReport(response);
			setCampaign(response.campaign);
			setIsLoadingCampaign(false);
		}
	};

	const getCampaignReports = async () => {
		if (campaign) {
			const response = await GetCampaignReports(campaign.id);

			if (response && response.reports && response.reports.length > 0) {
				// let tempCampaignReports = response.reports.filter((x) => {
				// 	return x.status && x.status === 'Active';
				// });

				let tempCampaignReports = response.reports;

				setCampaignReports(tempCampaignReports);

				// setCampaignReports(
				// 	tempCampaignReports.filter((x) => {
				// 		if (x.status) {
				// 			return x.status === 'Active';
				// 		} else {
				// 			return x;
				// 		}
				// 	})
				// );

				let tempReportIdValue = tempCampaignReports[0].id;
				let tempCompareToReportId;

				if (queryParamEndDate) {
					tempReportIdValue = queryParamEndDate;
				}
				// else {
				// 	tempReportIdValue = tempCampaignReports[0].id;
				// }

				if (tempReportIdValue) {
					let currentReport = tempCampaignReports.find((r) => r.id === tempReportIdValue);

					if (currentReport) {
						let tempCompareToReports = tempCampaignReports.filter(
							(r) =>
								r.id !== currentReport.id &&
								r.dateUnix < currentReport.dateUnix &&
								r.status === 'Active'
						);

						setCompareToReports(tempCompareToReports);

						//find the oldest report
						if (tempCompareToReports && tempCompareToReports.length > 0) {
							const oldestCompareToReport = tempCompareToReports.reduce(
								(r, o) => (o.dateCreated < r.dateCreated ? o : r)
							);

							if (queryParamStartDate) {
								tempCompareToReportId = queryParamStartDate;
							} else {
								if (oldestCompareToReport) {
									tempCompareToReportId = oldestCompareToReport.id;
								} else {
									tempCompareToReportId = '0';
								}
							}
						} else {
							tempCompareToReportId = '0';
						}
					} else {
						tempCompareToReportId = '0';
					}

					if (!demoId) {
						setReportIdValue(tempReportIdValue);
						setCompareToReportId(tempCompareToReportId);
					}
				} else {
					setCompareToReports();
				}
			} else {
				setCampaignReports();
				setCompareToReports();

				setNoReports(true);
				setIsLoadingReport(false);
			}
		}
	};

	const getCampaignKeywords = async (reportId) => {
		//const response = await GetSEOReportKeywords(campaign.id, reportId);
		const response = await GetCampaignKeywords(campaign.id, reportId);

		if (response && response.length) {
			let tempKeywords = response;

			setKeywordObjects(
				tempKeywords.sort(function(a, b) {
					return a.orderIndex - b.orderIndex;
				})
			);

			//setActiveKeyword(tempKeywords[0].keyword);
		}
	};

	const getReport = async (id, compareId) => {
		setIsLoadingReport(true);

		if (campaign) {
			let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

			let response = await GetSEOReportV2(campaign.id, id, targetId, compareId);

			if (response.success && response.data && response.data.length > 0) {
				let reports = response.data;

				//------ base report ------
				let tempReport = reports[0];
				tempReport.reportId = id;

				//order keywords by order index
				if (tempReport.gridResponse && tempReport.gridResponse.data && keywordObjects) {
					tempReport.gridResponse.data.forEach((gridKeywordObject) => {
						//get order index from db keywords and apply it to appropriate grid keyword
						let dbKeywordObject = keywordObjects.find((x) => x.keyword === gridKeywordObject.keyword);

						if (dbKeywordObject) {
							gridKeywordObject.orderIndex = dbKeywordObject.orderIndex;
						}
					});

					//order keywords by index
					tempReport.gridResponse.data = tempReport.gridResponse.data.sort(function(a, b) {
						return a.orderIndex - b.orderIndex;
					});
				}

				setReport(tempReport);
				setSelectedReportName(getReportNameByDate(tempReport.reportGeneratedAtUnix));
				setRankResponse(tempReport.rankResponse);

				//------- compare report -------
				if (reports.length > 1) {
					let tempCompareToReport = reports[1];
					tempCompareToReport.reportId = id;
					setCompareToReport(tempCompareToReport);
					setCompareToReportName(getReportNameByDate(tempCompareToReport.reportGeneratedAtUnix));
					setCompareToRankResponse(tempCompareToReport.rankResponse);
				}
			} else {
				setReport();
				setReportIdValue();
				setCompareToReport();
			}
		}
	};

	const handleKeywordSelected = (keywordData) => {
		let gridData = report.gridResponse;

		setTargetKeyword({
			lat              : gridData.lat,
			lng              : gridData.lng,
			horizontalPoints : gridData.horizontalPoints,
			verticalPoints   : gridData.verticalPoints,
			...keywordData
		});

		if (compareToReport && compareToReport.gridResponse && compareToReport.gridResponse.data) {
			let gridDataCompareTo = compareToReport.gridResponse;
			let selectedCompareToKeyword = gridDataCompareTo.data.find((k) => k.keyword === keywordData.keyword);

			if (!selectedCompareToKeyword) {
				//set empty keyword to prevent code break
				selectedCompareToKeyword = {
					keyword : keywordData.keyword,
					points  : []
				};
			}

			setCompareToKeyword({
				lat              : gridDataCompareTo.lat,
				lng              : gridDataCompareTo.lng,
				horizontalPoints : gridDataCompareTo.horizontalPoints,
				verticalPoints   : gridDataCompareTo.verticalPoints,
				...selectedCompareToKeyword
			});
		}

		setActiveKeyword(keywordData.keyword);
	};

	const getReportNameByDate = (timestamp) => {
		const format = new Intl.DateTimeFormat('en-US', {
			year  : 'numeric',
			month : 'long',
			day   : '2-digit'
		});

		return format.format(new Date(timestamp * 1000));
	};

	const handleMapFullScreen = (isMapFullScreen) => {
		setMapFullScreen(isMapFullScreen);

		if (!isMapFullScreen) {
			document.querySelector('body').scrollIntoView({
				behavior : 'instant'
			});
		}

		document.body.classList.toggle('body-full-screen');
	};

	const getReportShareUrl = () => {
		var url = new URL(`${host}/view/report/v5/${campaign.id}/`);

		url.searchParams.set('keyword', targetKeyword.keyword);

		if (compareToReportId) {
			url.searchParams.append('startDate', compareToReportId);
		}

		if (reportIdValue) {
			url.searchParams.append('endDate', reportIdValue);
		}

		return url.href;
	};

	const handleGenerateShareLink = () => {
		var url = getReportShareUrl();

		shareLinkRef.current.title = `Clik to Copy: ${url}`;

		copyToClipboard(url);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 2000);
	};

	const toggleSendReportDialog = async () => {
		setShowSendReportDialog(!showSendReportDialog);
	};

	const toggleGenerateContentDialog = () => {
		setShowGenerateContentDialog(!showGenerateContentDialog);
	};

	const createDemoReport = async () => {
		let data = {
			campaignId : campaign.id,
			keyword    : targetKeyword.keyword,
			startDate  : compareToReportId,
			endDate    : reportIdValue
		};

		const response = await AddDemoReport(campaign.id, data);

		if (response.success && response.data && response.data.id) {
			//toast.success('Report set as sent successfully.');

			window.open(`${host}/demo/${response.data.id}`, '_blank', 'noreferrer');
		}
	};

	const toggleReportSettingsDialog = async () => {
		setShowReportSettingsDialog(!showReportSettingsDialog);

		if (!showReportSettingsDialog) {
			getReportSettings();
		}
	};

	const handleReportSettingsFormDataChange = async (e) => {
		setReportSettingsFormData({
			...reportSettingsFormData,
			[e.target.name]: e.target.value
		});

		if (e.target.name.indexOf('ColorValue') > -1) {
			let colorControlName = e.target.name.replace('Value', '');
			setReportSettingsFormData({
				...reportSettingsFormData,
				[colorControlName] : e.target.value
			});
		}
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettingsFormData(response.data);
		}
	};

	const handleSaveReportSettings = async () => {
		setIsSubmittingSales(true);

		let data = { dataJson: JSON.stringify(reportSettingsFormData) };

		let response = await UpdateReportSettings(data);

		if (response.success) {
			toggleReportSettingsDialog();
			toast.success('Report settings updated successfully.');
		} else {
			toast.error(response.message);
		}

		setIsSubmittingSales(false);
	};

	return (
		<div className="campaign-v-p-report-v5 pt-0">
			{isLoadingCampaign ? (
				<Spinner />
			) : (
				<Fragment>
					{isCampaignCanceled && (
						<Fragment>
							{user && (
								<div className="container text-start px-0 py-2">
									<h5 className="text-truncate">{campaign.name}</h5>
								</div>
							)}
							<h4 className="py-5 fs-4 fw-bold">This campaign is canceled</h4>
						</Fragment>
					)}

					{isCampaignDeleted && (
						<Fragment>
							{user && (
								<div className="container text-start px-0 py-2">
									<h5 className="text-truncate">{campaign.name}</h5>
								</div>
							)}
							<h4 className="py-5 fs-4 fw-bold">
								{user ? 'This campaign is deleted' : 'Something went wrong'}
							</h4>
						</Fragment>
					)}
				</Fragment>
			)}

			{!isCampaignCanceled &&
			!isCampaignDeleted && (
				<Fragment>
					<div className="report-header-container">
						{isLoadingCampaign ? (
							<Spinner />
						) : (
							<div className="container text-start px-0 py-2">
								{campaign && (
									<div className="row">
										<div
											className={`${mobileReportView
												? 'col-12 text-center mb-2'
												: 'col-8'} align-self-center`}
										>
											<h5 className="text-truncate">{campaign.name}</h5>
										</div>
										<div className={`${mobileReportView ? 'col-12' : 'col-4'}`}>
											<div className="report-headline-links-wrapper">
												{showShareLinkCopiedMessage && (
													<button className="btn btn-outline-success py-0 px-2 me-3">
														Copied!
													</button>
												)}

												{!demoReport && (
													<button
														target="_blank"
														className="btn btn-link report-headline-link"
														href="#"
														onClick={handleGenerateShareLink}
														title="Report Link"
														ref={shareLinkRef}
													>
														<i className="fa-solid fa-share-nodes" />
													</button>
												)}

												<a
													target="_blank"
													className="btn btn-link report-headline-link"
													href={
														campaign.url && campaign.url.indexOf('http') > -1 ? (
															campaign.url
														) : (
															`https://${campaign.url}`
														)
													}
													title="Directory Url"
												>
													<i className="fa-solid fa-map-location-dot" />
												</a>
												{campaign.googleCid && (
													<a
														target="_blank"
														className="btn btn-link report-headline-link"
														href={`https://google.com/maps/?cid=${campaign.googleCid}`}
														title="Google Profile"
													>
														<i className="fa-brands fa-google" />
													</a>
												)}
												{user &&
												(user.isAdmin || user.isSalesUser) && (
													<Fragment>
														{!demoReport && (
															<Fragment>
																<button
																	target="_blank"
																	className="btn btn-link report-headline-link"
																	href="#"
																	onClick={createDemoReport}
																	title="Create Demo Report"
																>
																	<i className="fa-solid fa-dollar-sign" />
																</button>

																<button
																	target="_blank"
																	className="btn btn-link report-headline-link"
																	href="#"
																	onClick={toggleGenerateContentDialog}
																	title="Generate Content"
																>
																	<i className="fa-regular fa-file-lines" />
																</button>

																<button
																	target="_blank"
																	className="btn btn-link report-headline-link"
																	href="#"
																	onClick={toggleSendReportDialog}
																	title="Send Report"
																>
																	<i className="fa-regular fa-envelope" />
																</button>
															</Fragment>
														)}

														<button
															target="_blank"
															className="btn btn-link report-headline-link ms-4"
															href="#"
															onClick={toggleReportSettingsDialog}
															title="Report Settings"
														>
															<i className="fa-solid fa-gear" />
														</button>
													</Fragment>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						)}

						{noReports && <div className="no-reports-notice">No reports</div>}

						{isCampaignCanceled && (
							<div className="canceled-campaign-warning">This campaign is currently inactive</div>
						)}

						{!!campaignReports &&
						campaignReports.length > 0 && (
							<div className={`${mobileReportView ? '' : 'report-keyword-container'} container mb-3`}>
								{isLoadingReport ? (
									<Spinner />
								) : (
									<Fragment>
										{report &&
										report.gridResponse &&
										report.gridResponse.data && (
											<div className="row">
												<div className={`${mobileReportView ? 'col-12 p-0' : 'col-12'}`}>
													<GridKeywordPickerArrowsV5
														campaign={campaign}
														gridData={report.gridResponse}
														activeKeywordData={targetKeyword}
														onKeywordSelected={handleKeywordSelected}
														mapFullScreen={mapFullScreen}
														demoId={demoId}
													/>
												</div>
											</div>
										)}
									</Fragment>
								)}
							</div>
						)}
					</div>

					<div className="report-body">
						{mobileReportView ? (
							<Fragment>
								<div className="container campaign-reports mb-3">
									<div className="row">
										{!!compareToReports &&
										compareToReports.length > 0 && (
											//compareToKeyword &&
											<div className="col-12">
												{/* <div className="flex-fill mb-2">
													<small className="text-muted">Begin Date</small>
												</div> */}

												<div className="mb-3">
													<select
														className="form-select"
														onChange={(e) => {
															setCompareToReportId(e.target.value);
														}}
														value={compareToReportId}
														disabled={demoId ? 'disabled' : ''}
													>
														<option value="" disabled>
															Compare to...
														</option>
														{compareToReports.map((report) => (
															<option value={report.id} key={report.id}>
																{moment(report.dateCreated).format(
																	'MMMM DD, YYYY [at] hh:mm A'
																)}
															</option>
														))}
													</select>
												</div>
											</div>
										)}

										<div className="col-12">
											{/* <div className="flex-fill mb-2">
												<small className="text-muted">End Date</small>
											</div> */}

											<div className="mb-1">
												<select
													value={reportIdValue}
													className="form-select"
													onChange={(e) => {
														setReportIdValue(e.target.value);
														setCompareToReportId('');
														setReport();
														//setIsLoading(true);
														setIsLoadingReport(true);
													}}
													disabled={demoId ? 'disabled' : ''}
												>
													{!!campaignReports &&
														campaignReports.length > 0 &&
														campaignReports.map((report) => (
															<option value={report.id} key={report.id}>
																{moment(report.dateCreated).format(
																	'MMMM DD, YYYY [at] hh:mm A'
																)}
															</option>
														))}
												</select>
											</div>
										</div>
									</div>
								</div>

								<div className="container campaign-reports mb-4">
									<div className="row">
										<GridReportToggleTabsV5
											reportName={selectedReportName}
											compareToReportName={compareToReportName}
										/>
									</div>

									<div className="report-notice-wrapper mt-1 mb-4">
										<h4 className="report-notice-title mb-2">Google Business Profile Rankings</h4>
										<p className="report-notice-description mb-0">
											The profile ranking grid report below shows where your business profile is
											currently ranked at each of the longitude and latitude points on the map.
											Our software will set its long/lat position and then do a search for the
											selected keyword on Google Maps and return the position that your profile is
											currently ranking.
										</p>
									</div>

									<div className="row">
										{!!compareToReports &&
										compareToReports.length > 0 &&
										//compareToKeyword &&
										mobileReportView === 'compare' && (
											<div className="col-12">
												<div className="mb-3">
													<KeywordMapStatsV4
														keywordData={compareToKeyword || {}}
														mobileReportView={mobileReportView}
													/>

													<div className="map-wrapper mb-1">
														<GridMapV5
															key="map-1"
															gridData={compareToKeyword}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															isFullScreen={mapFullScreen}
															reportSettings={reportSettingsFormData}
														/>
													</div>
												</div>
											</div>
										)}

										<div className="col-12">
											{targetKeyword &&
											mobileReportView === 'base' && (
												<div className="col-12">
													<div className="mb-3">
														<KeywordMapStatsV4
															keywordData={targetKeyword || {}}
															mobileReportView={mobileReportView}
														/>
													</div>

													<div className="map-wrapper mb-1">
														<GridMapV5
															key="map-0"
															gridData={targetKeyword}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															isFullScreen={mapFullScreen}
															reportSettings={reportSettingsFormData}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>

								<div className="container report-notice-wrapper mb-4">
									<h4 className="report-notice-title mb-2">Map Boost Directory Page Rankings</h4>
									<p className="report-notice-description mb-0">
										The page rank report below shows the organic search rank position of the
										directory site pages{' '}
										{campaign &&
										campaign.url && <span className="me-2">{`(${campaign.url})`}</span>}that we
										create as part of the Map Boost service that is what helps influence Google to
										show your business profile in more areas in and around your service area. Click
										on any of the locations in the list to see where the page is currently ranking
										in Google search results.
									</p>
								</div>

								{campaign &&
								campaign.url && (
									<div className="campaign-reports py-2 mb-4 text-center">
										<div className="campaign-url-wrapper">
											<a
												className="btn btn-link"
												href={`https://${campaign.url}`}
												target="_blank"
											>
												{campaign.url}
											</a>
										</div>
									</div>
								)}

								<div className="container campaign-reports">
									{rankResponse && (
										<RankReportV5
											rankData={rankResponse}
											compareToRankData={compareToRankResponse}
											targetKeyword={activeKeyword}
											campaign={campaign}
										/>
									)}
								</div>
							</Fragment>
						) : (
							<Fragment>
								<div className="container mb-4">
									<div className="row mt-2 mb-4">
										<div className="col-12 p-0">
											<div className="report-notice-wrapper py-3">
												<h3 className="report-notice-title mb-2">
													Google Business Profile Rankings
												</h3>
												<p className="report-notice-description mb-0">
													The profile ranking grid report below shows where your business
													profile is currently ranked at each of the longitude and latitude
													points on the map. Our software will set its long/lat position and
													then do a search for the selected keyword on Google Maps and return
													the position that your profile is currently ranking.
												</p>
											</div>
										</div>
									</div>

									<div className="row">
										{isLoadingReport && (
											<Fragment>
												{!!compareToReports && compareToReports.length > 0 ? (
													<Fragment>
														<div className="col-lg-6 col-12 p-0">
															<div className="campaign-reports me-2 py-5">
																<Spinner />
															</div>
														</div>
														<div className="col-lg-6 col-12 p-0">
															<div className="campaign-reports py-5">
																<Spinner />
															</div>
														</div>
													</Fragment>
												) : (
													<div className="col-12 p-0">
														<div className="campaign-reports py-5">
															<Spinner />
														</div>
													</div>
												)}
											</Fragment>
										)}

										{!isLoadingReport && (
											<Fragment>
												<div className="col-lg-6 col-12 p-0">
													<Fragment>
														{!!campaignReports &&
														campaignReports.length > 0 &&
														compareToReport &&
														!!compareToReports &&
														compareToReports.length > 0 && (
															//compareToKeyword &&
															<div className="campaign-reports me-2">
																<div className="mb-3">
																	<select
																		className="form-select"
																		onChange={(e) => {
																			setCompareToReportId(e.target.value);
																		}}
																		value={compareToReportId}
																		disabled={demoId ? 'disabled' : ''}
																	>
																		<option value="" disabled>
																			Compare to...
																		</option>
																		{compareToReports.map((report) => (
																			<option value={report.id} key={report.id}>
																				{moment(report.dateCreated).format(
																					'MMMM DD, YYYY [at] hh:mm A'
																				)}
																			</option>
																		))}
																	</select>
																</div>

																{(!mobileReportView ||
																	mobileReportView === 'compare') && (
																	<div className="mb-3">
																		<KeywordMapStatsV4
																			keywordData={compareToKeyword || {}}
																			mobileReportView={mobileReportView}
																		/>
																	</div>
																)}

																<div className="map-wrapper mb-1">
																	<GridMapV5
																		key="map-1"
																		gridData={compareToKeyword}
																		placeId={campaign.googlePlaceId}
																		placeCid={campaign.googleCid}
																		isFullScreen={mapFullScreen}
																		reportSettings={reportSettingsFormData}
																		isCompareReport={true}
																	/>
																</div>
															</div>
														)}
													</Fragment>
												</div>

												{!!campaignReports &&
												campaignReports.length > 0 && (
													<div
														className={`${!!compareToReports &&
															compareToReports.length > 0 &&
															'col-lg-6'} col-12 p-0`}
													>
														<div
															className={`campaign-reports ${!!compareToReports &&
																compareToReports.length > 0 &&
																'ms-2'}`}
														>
															<div className="mb-3">
																<select
																	value={reportIdValue}
																	className="form-select"
																	onChange={(e) => {
																		setReportIdValue(e.target.value);
																		setCompareToReportId('');
																		setReport();
																		//setIsLoading(true);
																		setIsLoadingReport(true);
																	}}
																	disabled={demoId ? 'disabled' : ''}
																>
																	{campaignReports.map((report) => (
																		<option value={report.id} key={report.id}>
																			{moment(report.dateCreated).format(
																				'MMMM DD, YYYY [at] hh:mm A'
																			)}
																		</option>
																	))}
																</select>
															</div>

															{report &&
															report.gridResponse &&
															targetKeyword &&
															(!mobileReportView || mobileReportView === 'base') && (
																<div className="mb-3">
																	<KeywordMapStatsV4
																		keywordData={targetKeyword || {}}
																		mobileReportView={mobileReportView}
																	/>
																</div>
															)}

															<div className="map-wrapper mb-1">
																<GridMapV5
																	key="map-0"
																	gridData={targetKeyword}
																	placeId={campaign.googlePlaceId}
																	placeCid={campaign.googleCid}
																	isFullScreen={mapFullScreen}
																	reportSettings={reportSettingsFormData}
																	isSinglePublicView={
																		!!compareToReports &&
																		compareToReports.length > 0 ? (
																			false
																		) : (
																			true
																		)
																	}
																/>
															</div>
														</div>
													</div>
												)}
											</Fragment>
										)}
									</div>
								</div>

								<div className="container mb-3 p-0">
									<div className="report-notice-wrapper py-3 mb-4">
										<h3 className="report-notice-title mb-2">Map Boost Directory Page Rankings</h3>
										<p className="report-notice-description mb-0">
											The page rank report below shows the organic search rank position of the
											directory site pages{' '}
											{campaign &&
											campaign.url && <span className="me-2">{`(${campaign.url})`}</span>}that we
											create as part of the Map Boost service that is what helps influence Google
											to show your business profile in more areas in and around your service area.
											Click on any of the locations in the list to see where the page is currently
											ranking in Google search results.
										</p>
									</div>

									{campaign &&
									campaign.url && (
										<div className="campaign-reports py-3 mb-4 text-center">
											<div className="campaign-url-wrapper">
												<a
													className="btn btn-link"
													href={`https://${campaign.url}`}
													target="_blank"
												>
													{campaign.url}
												</a>
											</div>
										</div>
									)}

									{isLoadingRanking ? (
										<div className="campaign-reports">
											<Spinner />
										</div>
									) : (
										<Fragment>
											{!!campaignReports &&
											campaignReports.length > 0 && (
												<div className="campaign-reports">
													{rankResponse && (
														<RankReportV5
															rankData={rankResponse}
															compareToRankData={compareToRankResponse}
															targetKeyword={activeKeyword}
															campaign={campaign}
														/>
													)}
												</div>
											)}
										</Fragment>
									)}
								</div>
							</Fragment>
						)}
					</div>

					<div className="container-fluid text-start mb-2">
						<div className="container">
							<div className="row">
								<div className="col-12 ps-0">
									<label>Next Report Date:</label>
									<label className="ms-3">
										{campaign && campaign.nextReportDate ? (
											moment(campaign.nextReportDate).format('yyyy-MM-DD')
										) : (
											''
										)}
									</label>
								</div>
							</div>
						</div>
					</div>

					{showGenerateContentDialog && (
						<GenerateContentDialog
							campaignId={campaignId}
							//campaign={campaign}
							//reports={campaignReports}
							onClose={toggleGenerateContentDialog}
						/>
					)}

					{showSendReportDialog && (
						<SendReportDialog
							campaignId={campaignId}
							reportId={reportIdValue}
							compareToReportId={compareToReportId}
							targetKeyword={targetKeyword}
							reportHistoryId={
								queryParameters && queryParameters.get('hid') ? queryParameters.get('hid') : null
							}
							top3TotalPagesCount={top3TotalPages}
							onClose={toggleSendReportDialog}
						/>
					)}

					{showReportSettingsDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<form className="report-settings-form">
									<div className="row mb-2">
										<div className="col-12">
											<h5>Map Settings</h5>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop3BackgroundColor" className="form-label col-6 mt-2">
											Top 3 Background Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop3BackgroundColor"
												value={reportSettingsFormData.mapMarkerTop3BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop3BackgroundColorValue"
												value={reportSettingsFormData.mapMarkerTop3BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop3TextColor" className="form-label col-6 mt-2">
											Top 3 Text Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop3TextColor"
												value={reportSettingsFormData.mapMarkerTop3TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop3TextColorValue"
												value={reportSettingsFormData.mapMarkerTop3TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="mapMarkerTop3BorderColor" className="form-label col-6 mt-2">
											Top 3 Border Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop3BorderColor"
												value={reportSettingsFormData.mapMarkerTop3BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop3BorderColorValue"
												value={reportSettingsFormData.mapMarkerTop3BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>

									<div className="row mb-1">
										<label
											htmlFor="mapMarkerTop10BackgroundColor"
											className="form-label col-6 mt-2"
										>
											Top 10 Background Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop10BackgroundColor"
												value={reportSettingsFormData.mapMarkerTop10BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop10BackgroundColorValue"
												value={reportSettingsFormData.mapMarkerTop10BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop10TextColor" className="form-label col-6 mt-2">
											Top 10 Text Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop10TextColor"
												value={reportSettingsFormData.mapMarkerTop10TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop10TextColorValue"
												value={reportSettingsFormData.mapMarkerTop10TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="mapMarkerTop10BorderColor" className="form-label col-6 mt-2">
											Top 10 Border Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop10BorderColor"
												value={reportSettingsFormData.mapMarkerTop10BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop10BorderColorValue"
												value={reportSettingsFormData.mapMarkerTop10BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>

									<div className="row mb-1">
										<label
											htmlFor="mapMarkerTop20BackgroundColor"
											className="form-label col-6 mt-2"
										>
											Top 20 Background Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop20BackgroundColor"
												value={reportSettingsFormData.mapMarkerTop20BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop20BackgroundColorValue"
												value={reportSettingsFormData.mapMarkerTop20BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop20TextColor" className="form-label col-6 mt-2">
											Top 20 Text Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop20TextColor"
												value={reportSettingsFormData.mapMarkerTop20TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop20TextColorValue"
												value={reportSettingsFormData.mapMarkerTop20TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="mapMarkerTop20BorderColor" className="form-label col-6 mt-2">
											Top 20 Border Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop20BorderColor"
												value={reportSettingsFormData.mapMarkerTop20BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop20BorderColorValue"
												value={reportSettingsFormData.mapMarkerTop20BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								<button className="btn btn-primary" type="button" onClick={handleSaveReportSettings}>
									{isSubmittingSales ? (
										<Fragment>
											<span className="spinner-border m-0 me-2" />
											<span>Saving</span>
										</Fragment>
									) : (
										<span>Save</span>
									)}
								</button>
								<button
									className="btn btn-outline-primary ms-2"
									type="button"
									onClick={toggleReportSettingsDialog}
								>
									Cancel
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default PublicViewCampaignReportScreenV5New;
