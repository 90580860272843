import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../StateProvider';
import './KeywordTool.css';
import { useNavigate } from 'react-router-dom';
import { GetKeywordIdeas } from '../../Services';
import { toast } from 'react-toastify';
import Spinner from '../spinner/Spinner';
import CenterContainer from '../centerContainer/CenterContainer';
import { copyToClipboard } from '../../utils/Common';

const KeywordTool = () => {
	const [ seedInputValue, setSeedInputValue ] = useState('');
	const [ country, setCountry ] = useState('2840');
	const [ { user }, dispatch ] = useStateValue();
	const [ isSearching, setIsSearching ] = useState(false);
	const [ keywordIdeas, setKeywordIdeas ] = useState([]);
	const [ searchType, setSearchType ] = useState('keyword');
	const [ responseStringForCopyAll, setResponseStringForCopyAll ] = useState();
	const [ responseStringForCopyGPT, setResponseStringForCopyGPT ] = useState();
	const navigation = useNavigate();

	useEffect(
		() => {
			if (!user) {
				navigation('/login');
			}
		},
		[ user ]
	);

	const handleSearchClick = async (event) => {
		event.preventDefault();

		let data = {
			locationId : country
		};

		if (searchType === 'keyword') {
			data.seedKeyword = seedInputValue;
		} else if (searchType === 'url') {
			data.type = 'UrlSeed';
			data.url = seedInputValue;
		}

		setIsSearching(true);

		const response = await GetKeywordIdeas(data);

		if (response && response.length > 0) {
			setKeywordIdeas(response);
			console.log(response);

			//set string for copy
			let tempResponseCopyAllString = '';
			let tempResponseCopyGPTString = '';
			response.forEach((kw) => {
				tempResponseCopyAllString += `${kw.keyword}, ${kw.searchVolume}, ${kw.competition}, $${kw.cpc}, $${kw.lowTopOfPageBid}, $${kw.highTopOfPageBid}\r\n`;
				tempResponseCopyGPTString += `${kw.keyword}, ${kw.searchVolume}\r\n`;

				setResponseStringForCopyAll(tempResponseCopyAllString);
				setResponseStringForCopyGPT(tempResponseCopyGPTString);
			});
		} else {
			toast.error(response.ErrorMessage || response.Message);
		}

		setIsSearching(false);
	};

	const handleCopyAll = () => {
		copyToClipboard(responseStringForCopyAll);
		toast.success('Copied to clipboard!');
	};

	const handleCopyKwAndVol = () => {
		copyToClipboard(responseStringForCopyGPT);
		toast.success('Copied to clipboard!');
	};

	return (
		<div className="container-fluid p-3">
			<form onSubmit={handleSearchClick}>
				<div className="search-container">
					<div className="search-type-box">
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="searchTypeToggle"
								id="searchTypeSeedKeyword"
								value="keyword"
								onChange={(e) => setSearchType(e.target.value)}
								checked={searchType === 'keyword'}
							/>
							<label className="form-check-label" htmlFor="searchTypeSeedKeyword">
								Seed Keyword
							</label>
						</div>
						<div className="form-check form-check-inline ms-3">
							<input
								className="form-check-input"
								type="radio"
								name="searchTypeToggle"
								id="searchTypeUrl"
								value="url"
								onChange={(e) => setSearchType(e.target.value)}
								checked={searchType === 'url'}
							/>
							<label className="form-check-label" htmlFor="searchTypeUrl">
								Seed Url
							</label>
						</div>
					</div>
					<div className="search-box">
						<input
							type="text"
							placeholder={`Enter seed ${searchType}`}
							value={seedInputValue}
							onChange={(e) => setSeedInputValue(e.target.value)}
							className="form-control"
						/>
					</div>
					<div className="search-countries">
						<select value={country} onChange={(e) => setCountry(e.target.value)} className="form-select">
							<option value="2840">United States</option>
							<option value="2826">United Kingdom</option>
							<option value="2124">Canada</option>
							<option value="2036">Australia</option>
						</select>
					</div>
					<div className="search-button d-grid">
						<button type="submit" className="btn btn-primary">
							Search
						</button>
					</div>
				</div>
			</form>

			{isSearching && (
				<div className="text-cemter">
					<Spinner />
				</div>
			)}

			{!isSearching && (
				<Fragment>
					{keywordIdeas.length > 0 ? (
						<Fragment>
							<h2>Keyword Ideas</h2>

							<div className="py-3">
								<button type="button" className="btn btn-outline-primary" onClick={handleCopyAll}>
									<span>Copy All</span>
								</button>
								<button
									type="button"
									className="btn btn-outline-primary ms-2"
									onClick={handleCopyKwAndVol}
								>
									<span>Copy KW & Vol</span>
								</button>
							</div>

							<table className="table table-striped">
								<thead>
									<tr>
										<th>Keyword</th>
										<th>Search Volume</th>
										<th>Competition</th>
										<th>CPC</th>
										<th>Low TOP Bid</th>
										<th>High TOP Bid</th>
									</tr>
								</thead>
								<tbody>
									{keywordIdeas.map((keyword, index) => (
										<tr key={index}>
											<td>{keyword.keyword}</td>
											<td>{keyword.searchVolume}</td>
											<td>{keyword.competition}</td>
											<td>${keyword.cpc}</td>
											<td>${keyword.lowTopOfPageBid}</td>
											<td>${keyword.highTopOfPageBid}</td>
										</tr>
									))}
								</tbody>
							</table>
						</Fragment>
					) : (
						<Fragment>
							<div className="alert alert-info">
								<p className="m-0">{`Enter a seed ${searchType} and country to get keyword ideas`}</p>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default KeywordTool;
