import axios from 'axios';
import { getToken } from './utils/StorageUtil';

let apiHost = 'https://gbpapi.gbpboost.com';
const imageServiceHost = 'https://fulfillmentws.realwebsite.com/Services/';
const apiKey = 'AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg';
const apiHeader = 'X-API-KEY';
const apiHeaderValue = 'IQO?EXZiro?-|m%X15;vx1Q>L}bxb,<?M7=(J56Ss[K7o+;^6a7}J!dW6i8LJk2';
const automationApiKey = '3efc81b0b24f49179c74af2a2614a06f';

if (window.location.origin) {
	if (
		window.location.origin.indexOf('localhost') > -1 ||
		window.location.origin.indexOf('dev.') > -1 ||
		window.location.origin.indexOf('dev-') > -1
	) {
		// apiHost = 'https://localhost:7256';
		// apiHost = 'https://cloudapi.gbpboost.com';
		apiHost = 'https://dev-api.gbpboost.com';
	}
}

// setup axios to inject the authorization header automatically
// if token is present in store
axios.interceptors.request.use((config) => {
	let token = getToken();

	if (token && token.trim() !== '') {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

export const GetLocations = async (lat, lng, radius, locationType = 'locality', language = 'en', keyword = null) => {
	let url = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json';
	url += '?location=' + lat;
	url += ',' + lng;
	url += '&radius=' + parseInt(radius * 1.60934 * 1000).toString();
	url += '&type=' + locationType;
	url += '&language=' + language;
	if (keyword) {
		url += '&keyword=' + keyword;
	}
	url += '&key=' + apiKey;

	const response = await axios
		.get(url, null, {
			headers : {
				'Content-Type' : 'application/json',
				'X-RW-API'     : apiHeaderValue
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetGooglePlaceByCid = async (cid) => {
	let url = 'https://maps.googleapis.com/maps/api/place/details/json';
	url += '?cid=' + cid;
	url += '&key=' + apiKey;

	const response = await axios
		.get(url, null, {
			headers : {
				'Content-Type' : 'application/json',
				'X-RW-API'     : apiHeaderValue
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GenerateSEOReport = async (data) => {
	const response = await axios
		.post(apiHost + '/seo/generate-report', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateSEOReportKeyword = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/update-report-keyword`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const MergeSEOReports = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/merge`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const MergeReportForward = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/merge-forward`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const MergeReportBackward = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/merge-backward`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeduplicateRankResults = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/deduplicate`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ArchiveSEOReport = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/archive`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UnarchiveSEOReport = async (reportId, data) => {
	const response = await axios
		.post(apiHost + `/seo/reports/${reportId}/unarchive`, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GenerateSEOReportPreview = async (data) => {
	const response = await axios
		.post(apiHost + '/seo/generate-report-preview', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GenerateSEOReportDataGrid = async (data) => {
	const response = await axios
		.post(apiHost + '/seo/generate-report-datagrid', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSEOReport = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	//---------------------------
	// let response = await fetch(
	// 	apiHost +
	// 		`/seo/reports/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo && compareTo !== ''
	// 			? '/' + compareTo
	// 			: ''}`
	// )
	// 	.then((response) => {
	// 		return response.text();
	// 	})
	// 	.catch((err) => {
	// 		return { data: { IsSuccessful: false, Message: err } };
	// 	});

	// if (response) {
	// 	response = JSON.parse(response);
	// }

	// return response;

	//   .then(response => {
	//     return response.json()
	//   })
	//   .then(data => {
	//     setUsers(data)
	//   })
	//---------------------------

	const response = await axios
		.get(
			apiHost +
				`/seo/reports/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo && compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReport' : 'GetSEOReport') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportV2 = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/v2/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo &&
				compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportV2' : 'GetSEOReportV2') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportWithSproc = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports-sproc/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo &&
				compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportWithSproc' : 'GetSEOReportWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportGridWithSproc = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/grid/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo &&
				compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportGridWithSproc' : 'GetSEOReportGridWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportRankWithSproc = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/rank/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo &&
				compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportRankWithSproc' : 'GetSEOReportRankWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportGridSingleKeywordWithSproc = async (campaignId, reportId, keyword, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/grid/keyword/${campaignId}/${reportId}/${keyword}/${targetId
					? targetId
					: ''}${compareTo && compareTo !== '' ? '/' + compareTo : ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportGridSingleKeywordWithSproc' : 'GetSEOReportGridSingleKeywordWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportRankSingleKeywordWithSproc = async (campaignId, reportId, keyword, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/rank/keyword/${campaignId}/${reportId}/${keyword}/${targetId
					? targetId
					: ''}${compareTo && compareTo !== '' ? '/' + compareTo : ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportRankSingleKeywordWithSproc' : 'GetSEOReportRankSingleKeywordWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetCampaignLastReportTop3RankPages = async (campaignId) => {
	const response = await axios
		.get(apiHost + `/campaigns/${campaignId}/reports/rank/top3count`, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSEOReportKeywords = async (campaignId, reportShortName) => {
	const response = await axios
		.get(apiHost + `/campaigns/reports/keywords/list/${campaignId}/${reportShortName}`, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSEOReportFromFiles = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/seo/reports/files/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo && compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReport' : 'GetSEOReport') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetCampaignSummaryReportStats = async (id) => {
	const response = await axios
		.get(apiHost + '/seo/' + id + '/reports/summary/stats', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCities = async (data) => {
	const response = await axios
		.post(apiHost + '/seo/cities', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetLocationsV2 = async (data) => {
	const response = await axios
		.post(apiHost + '/seo/towns-list', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaigns = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignsForSelect = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/select-list', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetActiveCampaigns = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/list/active', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCanceledCampaigns = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/canceled', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetNewCampaigns = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/new', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetApprovedCampaigns = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/approved', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignsForRunReports = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/run-reports', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaign = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/details/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignReports = async (id) => {
	const response = await axios
		.get(apiHost + '/seo/reports/list/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignReportsFromFiles = async (id) => {
	const response = await axios
		.get(apiHost + '/seo/reports/files/list/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignKeywords = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/keywords/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignKeywordDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/keywords/details/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignKeywordGroups = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/keywords/groups/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignKeywordGroupDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/keywords/groups/details/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignLocations = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/locations/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignServices = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/services/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignProducts = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/products/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignFiles = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/files/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignReviews = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/reviews/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetAllProcesses = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/processes/all', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetRunningProcesses = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/processes/running', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetQueueProcesses = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/processes/queue', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCompletedProcesses = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/processes/completed', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignProcesses = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/processes/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignRunningProcesses = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/processes/running', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignProcessDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/processes/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportQueueRunningList = async () => {
	const response = await axios
		.get(apiHost + '/automation/queue/running/list?apiKey=' + automationApiKey, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const IsCampaignRunningInQueue = async (id) => {
	const response = await axios
		.get(apiHost + '/automation/queue/' + id + '/is-running?apiKey=' + automationApiKey, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ProcessQueueReportJobs = async () => {
	const response = await axios
		.get(apiHost + '/seo/process-queue-report-jobs', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryList = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/list', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryListWithSproc = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/list/sproc', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/' + id, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryTrackingLogs = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/' + id + '/tracking-logs', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteReportHistoryItem = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/reports/history/' + id + '/delete', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateCampaign = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/create', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaign = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaign = async (id) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DuplicateCampaign = async (id) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/duplicate', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignImageUrls = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/images/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignStatus = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/status/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignReportDate = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/report-date/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateNewCampaignSettings = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/settings/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignKeywords = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/keywords', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignKeywordGroups = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/keywords/groups/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignLocations = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/locations', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignServices = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/services', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignProducts = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/products', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UploadCampaignFiles = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/files/upload', data, {
			headers : {
				// 'Content-Type' : 'application/json'
				mimeType : 'multipart/form-data'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaignFiles = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/files/delete', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaignFileByUrl = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/file/delete/url', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignReviews = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/reviews', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddCampaignProcess = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/processes/add', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignProcess = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/processes/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const RemoveRunningCampaignProcess = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/processes/delete', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const RemoveCampaignProcessById = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/processes/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const RemoveCampaignReportQueueById = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/report-queue/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddReportHistoryRecord = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/reports/history/add', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateReportHistoryRecord = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/reports/history/' + id + '/update', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const MarkReadyContentAsCompleted = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/reports/history/mark-as-completed', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SendReportByEmail = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/reports/history/send', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddReportHistoryOpenEvent = async (id) => {
	const response = await axios
		.get(apiHost + '/track/reports/' + id + '/logs/open/add', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddReportHistoryClickEvent = async (id, data) => {
	const response = await axios
		.post(apiHost + '/track/reports/' + id + '/logs/click/add', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const MergeCampaignReportsIntoDb = async (id) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/reports/db-merge', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetDemoReportsList = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/demos/list', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetDemoReport = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/demos/' + id, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddDemoReport = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/reports/demos/add', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GenerateCampaignAllContent = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/generate-all-content', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const RegenerateCampaignContent = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/regenerate-content', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const RegenerateCampaignContentTest = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/regenerate-content-test', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GenerateCampaignTestContent = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/generate-test-content', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignOrders = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/orders/list', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignOrderDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/orders/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateCampaignOrder = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/orders/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignOrder = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/orders/' + id + '/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DuplicateCampaignOrder = async (id) => {
	const response = await axios
		.post(apiHost + '/campaigns/orders/' + id + '/duplicate', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaignOrder = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/orders/' + id + '/delete', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetRequirements = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/requirements/list', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetRequirementDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/requirements/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateOrderRequirements = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/requirements/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateOrderRequirements = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/requirements/' + id + '/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SendRequirementsForApproval = async (id) => {
	const response = await axios
		.post(apiHost + '/campaigns/requirements/' + id + '/send-for-approval', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ApproveRequirements = async (id) => {
	const response = await axios
		.post(apiHost + '/campaigns/requirements/' + id + '/approve', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SendApprovedConfirmationEmails = async (id) => {
	const response = await axios
		.post(apiHost + '/campaigns/requirements/' + id + '/send-approved-email', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetRequirementsMessages = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/requirements/' + id + '/messages', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateRequirementsMessage = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/requirements/' + id + '/messages/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const RequirementsMessagesMarkAsSeen = async (id) => {
	const response = await axios
		.patch(apiHost + '/campaigns/requirements/' + id + '/messages/mark-as-seen', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteRequirements = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/requirements/' + id + '/delete', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignMessages = async (campaignId) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + campaignId + '/messages/list', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignMessagesDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/messages/details/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateCampaignMessage = async (campaignId, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + campaignId + '/messages/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CampaignMessagesMarkAsSeen = async (campaignId) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + campaignId + '/messages/mark-as-seen', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AppLogin = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/login', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AppLoginAs = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/login-as', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetLoginAsToken = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/token/get', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignDefaultSettings = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/settings/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignDefaultSettings = async (data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/settings/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCannedComments = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/canned-comments/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddCannedComment = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/canned-comments/add', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCannedComment = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/canned-comments/' + id + '/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCannedCommentsIndexes = async (data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/canned-comments/update-indexes', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCannedComment = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/canned-comments/' + id + '/delete', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignTimeZones = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/timezones/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportSettings = async () => {
	const response = await axios
		.get(apiHost + '/seo/reports/summary/settings/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateReportSettings = async (data) => {
	const response = await axios
		.patch(apiHost + '/seo/reports/summary/settings/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ProcessOrder = async (data) => {
	const response = await axios
		.post(apiHost + 'orders/process', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const LogFailedOrderAttempt = async (data) => {
	const response = await axios
		.post(apiHost + 'logs/failed', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const LogOrderAttempt = async (data) => {
	const response = await axios
		.post(apiHost + 'logs/success', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const LogOrderResponse = async (data) => {
	const response = await axios
		.post(apiHost + 'logs/response', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//products, offers
export const GetProducts = async () => {
	const response = await axios
		.get(apiHost + '/products/', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetProduct = async (id) => {
	const response = await axios
		.get(apiHost + '/products/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddProduct = async (data) => {
	const response = await axios
		.post(apiHost + '/products/add', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateProduct = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/products/' + id + '/update', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateProductsIndexes = async (data) => {
	const response = await axios
		.patch(apiHost + '/products/update-indexes', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteProduct = async (id) => {
	const response = await axios
		.delete(apiHost + '/products/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetUserOffers = async () => {
	const response = await axios
		.get(apiHost + '/offers/', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSystemOffers = async () => {
	const response = await axios
		.get(apiHost + '/offers/system', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetDefaultOffers = async () => {
	const response = await axios
		.get(apiHost + '/offers/default', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetOffer = async (id) => {
	const response = await axios
		.get(apiHost + '/offers/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddOffer = async (data) => {
	const response = await axios
		.post(apiHost + '/offers/add', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateOffer = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/offers/' + id + '/update', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateOffersIndexes = async (data) => {
	const response = await axios
		.patch(apiHost + '/offers/update-indexes', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteOffer = async (id) => {
	const response = await axios
		.delete(apiHost + '/offers/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//orders, payments
export const GetUserPayments = async () => {
	const response = await axios
		.get(apiHost + '/orders/user-payments', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ChargeUserPayment = async (id) => {
	const response = await axios
		.post(apiHost + '/orders/payment/' + id + '/charge', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//landing pages
export const GetCampaignLandingPages = async (campaignId) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + campaignId + '/landing-pages/list', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignLandingPageDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/landing-pages/' + id + '/details', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AddCampaignLandingPage = async (campaignId, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + campaignId + '/landing-pages/add', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateCampaignLandingPage = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/landing-pages/' + id + '/update', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaignLandingPage = async (id) => {
	const response = await axios
		.delete(apiHost + '/campaigns/landing-pages/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//account
export const RegisterUser = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/register', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateUser = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/accounts/update/' + id, JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetUsers = async () => {
	const response = await axios
		.get(apiHost + '/accounts/list', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSalesUsers = async () => {
	const response = await axios
		.get(apiHost + '/accounts/list/sales', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetUser = async () => {
	const response = await axios
		.get(apiHost + '/accounts/details', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetUserById = async (id) => {
	const response = await axios
		.get(apiHost + '/accounts/details/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GenerateCampaignAIContent = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/generate-ai-content', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetKeywordItems = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/keyword-items/get', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UploadFile = async (data) => {
	const response = await axios
		.post(apiHost + '/files/upload', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//sales
export const SalesCompanyLogin = async (data) => {
	const response = await axios
		.post(apiHost + '/salescompanies/login', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSalesCompanies = async () => {
	const response = await axios
		.get(apiHost + '/salescompanies/list', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCurrentUserSalesCompanies = async () => {
	const response = await axios
		.get(apiHost + '/salescompanies/current-user/list', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetUserSalesCompanies = async (userId) => {
	const response = await axios
		.get(apiHost + '/salescompanies/' + userId + '/list', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSalesCompanyDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/salescompanies/' + id + '/details', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSalesCompanyDetailsBasic = async (id) => {
	const response = await axios
		.get(apiHost + '/salescompanies/' + id + '/details/basic', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateSalesCompany = async (data) => {
	const response = await axios
		.post(apiHost + '/salescompanies/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateSalesCompany = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/salescompanies/' + id + '/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSalesCompanyTasks = async (id) => {
	const response = await axios
		.get(apiHost + '/salescompanies/' + id + '/tasks', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetSalesCompanyTaskDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/salescompanies/tasks/' + id + '/details', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateSalesCompanyTask = async (data) => {
	const response = await axios
		.post(apiHost + '/salescompanies/' + data.salesCompanyId + '/tasks/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteSalesCompanyTask = async (salesCompanyId, id) => {
	const response = await axios
		.delete(apiHost + '/salescompanies/' + salesCompanyId + '/tasks/' + id + '/delete', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const FindGoogleServiceAreaBusiness = async (data) => {
	const response = await axios
		.post(apiHost + '/seo/find-sab', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SerpFindGoogleProfileByPlaceId = async (placeId) => {
	const response = await axios
		.get(apiHost + '/seo/find-profile?placeId=' + placeId, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetGooglePlaceReviews = async (placeId) => {
	const response = await axios
		.get(apiHost + '/seo/place/' + placeId + '/reviews', null, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const FindGoogleProfileInformation = async (url) => {
	const response = await axios
		.get(apiHost + '/seo/profile-lookup?mapsUrl=' + url, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//DepositPhotos Images -----------------
export const GetDPImages = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'DepositPhotosService.asmx/GetPhotosByTerm', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

export const GetRelatedDPImages = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'DepositPhotosService.asmx/GetRelatedPhotos', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

export const GetDPImageByID = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'DepositPhotosService.asmx/GetPhotoById', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

export const DownloadDPImage = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'DepositPhotosService.asmx/DownloadPhoto', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

export const ReDownloadDPImage = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'DepositPhotosService.asmx/ReDownloadPhoto', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

//NounProject Icons ---------------------
export const GetIcons = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'NounProjectService.asmx/GetIconsByTerm', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

export const GetIconById = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'NounProjectService.asmx/GetIconById', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data.d;
};

export const OptimizeImage = async (data) => {
	const response = await axios
		.post(imageServiceHost + 'Service.asmx/OptimizeImage', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data.d;
};

export const GetKeywordIdeas = async (data) => {
	const response = await axios
		.post(apiHost + '/keywords/seed-search', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};
