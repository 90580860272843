import './GenerateContentDialog.css';
import { Fragment, useState, useEffect } from 'react';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import {
	AddCampaignProcess,
	GenerateCampaignAllContent,
	GetCampaign,
	GetCampaignDefaultSettings,
	GetCampaignKeywords,
	GetCampaignReports,
	GetCampaignRunningProcesses,
	GetKeywordItems,
	RegenerateCampaignContent,
	RegenerateCampaignContentTest,
	RemoveRunningCampaignProcess
} from '../../../Services';
import { UnixTimestampToDateTime } from '../../../utils/DateTime';
import KeywordMapStatsV4 from '../v4/keywordMapStats/KeywordMapStatsV4';
import Spinner from '../../spinner/Spinner';
import moment from 'moment';
import { getDuration } from '../../../utils/Common';

function GenerateContentDialog({ campaignId, campaign, reports, onClose }) {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ generateContentFormData, setGenerateContentFormData ] = useState({});
	const [ campaignDetails, setCampaignDetails ] = useState();
	const [ campaignKeywords, setCampaignKeywords ] = useState([]);
	const [ campaignReports, setCampaignReports ] = useState();
	const [ campaignRunningContentJobs, setCampaignRunningContentJobs ] = useState();
	const [ isLoadingRunningContentJobs, setIsLoadingRunningContentJobs ] = useState(false);
	const [ isGeneratingContent, setIsGeneratingContent ] = useState(false);
	const [ isRegeneratingContent, setIsRegeneratingContent ] = useState(false);
	const [ isRegeneratingContentTest, setIsRegeneratingContentTest ] = useState(false);
	const [ isGettingKeywordItems, setIsGettingKeywordItems ] = useState(false);
	const [ regenerateContentTestCommand, setRegenerateContentTestCommand ] = useState();
	const [ regenerateContentTestResponse, setRegenerateContentTestResponse ] = useState();
	const [ showRegenerateContentTestDialog, setShowRegenerateContentTestDialog ] = useState(false);
	const [ industry, setIndustry ] = useState('');
	const [ defaultSettings, setDefaultSettings ] = useState();
	const [ showRunningJobs, setShowRunningJobs ] = useState(false);
	const [ keywordItemsStr, setKeywordItemsStr ] = useState();
	const [ preselectRankingType, setPreselectRankingType ] = useState();

	useEffect(
		() => {
			if (!campaign || campaign === null || campaign === undefined) {
				getCampaign();
			} else {
				setCampaignDetails(campaign);
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaignDetails) {
				getCampaignKeywords();
				getDefaultSettings();
				getCampaignRunningProcesses(true);

				if (!reports) {
					getCampaignReports();
				} else {
					setCampaignReports(reports);
				}

				setIndustry(campaignDetails.listingType);

				let processesInterval = setInterval(() => {
					getCampaignRunningProcesses();
				}, 5000);

				return () => {
					clearInterval(processesInterval);
				};
			}
		},
		[ campaignDetails ]
	);

	useEffect(
		() => {
			if (defaultSettings) {
				setGenerateContentFormData({
					...generateContentFormData,
					isProcessingContent       : true,
					isProcessingHeadlines     : false,
					contentCommand            : defaultSettings.keywordsContentPrompt,
					headlineCommand           : defaultSettings.keywordsHeadlinePrompt,
					keywordPostHeadlinePrompt : defaultSettings.keywordPostHeadlinePrompt,
					keywordPostContentPrompt  : defaultSettings.keywordPostContentPrompt
				});
			}
		},
		[ defaultSettings ]
	);

	useEffect(
		() => {
			if (campaignDetails && defaultSettings && campaignReports) {
				setIsLoading(false);

				setGenerateContentFormData({
					...generateContentFormData,
					report : campaignReports[0].id
				});
			}
		},
		[ campaignDetails, defaultSettings, campaignReports ]
	);

	const handleCloseDialog = () => {
		if (onClose) {
			onClose(campaignId);
		}
	};

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			setCampaignDetails(response);
		}
	};

	const getCampaignReports = async () => {
		const response = await GetCampaignReports(campaignId);

		if (response && response.reports && response.reports.length > 0) {
			setCampaignReports(
				response.reports.filter((x) => {
					if (x.status) {
						return x.status === 'Active';
					} else {
						return x;
					}
				})
			);
		}
	};

	const getCampaignKeywords = async () => {
		const response = await GetCampaignKeywords(campaignId);

		if (response) {
			let tempKeywords = response;

			tempKeywords = tempKeywords.sort(function(a, b) {
				return a.orderIndex - b.orderIndex;
			});

			for (let i = 0; i < tempKeywords.length; i++) {
				tempKeywords[i].index = i;
			}

			setCampaignKeywords(tempKeywords);
		}
	};

	const getCampaignRunningProcesses = async (showRunningJobsList = false) => {
		const response = await GetCampaignRunningProcesses(campaignId);

		if (response && response.length > 0) {
			setCampaignRunningContentJobs(
				response.filter((x) => {
					return x.type === 'Content' || x.type === 'Content Update';
				})
			);

			if (showRunningJobsList) {
				setShowRunningJobs(true);
			}
		} else {
			setShowRunningJobs(false);
		}
	};

	const getKeywordItems = async (e, industryValue) => {
		if (e) e.preventDefault();

		if (!defaultSettings.keywordItemsContentPrompt || defaultSettings.keywordItemsContentPrompt.trim() === '') {
			toast.error('Please set the keyword items command first.');
			return;
		}

		setIsGettingKeywordItems(true);

		let tempInstructions = defaultSettings.keywordItemsContentPrompt;

		if (industryValue) {
			tempInstructions = tempInstructions.replaceAll('{INDUSTRY}', industryValue);
		}

		let data = {
			instructions : tempInstructions
		};

		const response = await GetKeywordItems(campaignId, data);

		if (response.success && response.items) {
			let items = [];

			//remove numbers from response items
			for (let i = 0; i < response.items.length; i++) {
				let item = response.items[i];

				item = item.replace(/\d\.\s+|[a-z]\)\s+|•\s+|[A-Z]\.\s+|[IVX]+\.\s+/g, ''); //remove bullets
				item = item.replace(/^-+/, ''); //remove hyphen
				item = item.replace(/^[0-9]+/, ''); //remove starting number
				item = item.trim();

				//add to list if it doesn't exist
				let itemFound = items.find((x) => x.toString().toLowerCase() === item.toString().toLowerCase());

				if (!itemFound || itemFound === null) {
					items.push(item);
				}
			}

			setKeywordItemsStr(items.join('\n'));
		}

		setIsGettingKeywordItems(false);
	};

	useEffect(
		() => {
			if (keywordItemsStr) {
				setGenerateContentFormData({
					...generateContentFormData,
					keywordItems : keywordItemsStr
				});
			}
		},
		[ keywordItemsStr ]
	);

	const getDefaultSettings = async () => {
		let response = await GetCampaignDefaultSettings();

		if (response && response.data) {
			setDefaultSettings(response.data);
		}
	};

	const handleGenerateContentFormDataChange = async (e) => {
		const { name, type, value, checked } = e.target;

		if (type === 'checkbox') {
			if (name.indexOf('chk_Keyword_') !== -1) {
				let keywordName = name.replace('chk_Keyword_', '');
				let tempSelectedKeywords = generateContentFormData.keywords || [];

				if (checked) {
					tempSelectedKeywords.push(keywordName);
				} else {
					tempSelectedKeywords = tempSelectedKeywords.filter((x) => x !== keywordName);
				}

				let tempGenerateContentFormData = { ...generateContentFormData, keywords: tempSelectedKeywords };

				let selectedKeywordItems;
				if (campaignKeywords && campaignKeywords.length > 0) {
					let selectedKeyword = campaignKeywords.find(
						(x) => x.keyword && x.keyword.trim() === keywordName.trim()
					);

					if (selectedKeyword) {
						if (selectedKeyword.keywordItems) {
							selectedKeywordItems = selectedKeyword.keywordItems;
						}

						if (selectedKeyword.keywordPostHeadlinePrompt) {
							tempGenerateContentFormData.keywordPostHeadlinePrompt =
								selectedKeyword.keywordPostHeadlinePrompt;
						}

						if (selectedKeyword.keywordPostContentPrompt) {
							tempGenerateContentFormData.keywordPostContentPrompt =
								selectedKeyword.keywordPostContentPrompt;
						}
					}
				}

				setKeywordItemsStr(selectedKeywordItems.split('|').join('\n'));
				setGenerateContentFormData(tempGenerateContentFormData);
			} else {
				setGenerateContentFormData({
					...generateContentFormData,
					[name] : checked
				});
			}
		} else {
			setGenerateContentFormData({
				...generateContentFormData,
				[name] : value
			});
		}
	};

	const handleRankingTypeSelect = (isSelected, label) => {
		let tempSelectedRankingTypes = generateContentFormData.rankingTypes || [];

		if (isSelected) {
			tempSelectedRankingTypes.push(label);
		} else {
			tempSelectedRankingTypes = tempSelectedRankingTypes.filter((x) => x !== label);
		}

		setGenerateContentFormData({
			...generateContentFormData,
			rankingTypes : tempSelectedRankingTypes
		});
	};

	const generateCampaignContent = async (e) => {
		e.preventDefault();

		if (campaignId) {
			setIsGeneratingContent(true);

			let data = {
				id : campaignId
			};

			await AddCampaignProcess(campaignId, {
				campaignId : campaignId,
				type       : 'Content',
				status     : 'Running',
				data       : JSON.stringify(data)
			});

			//handleCloseDialog();
			setShowRunningJobs(true);
			setIsGeneratingContent(false);

			const response = await GenerateCampaignAllContent(campaignId, data);

			if (response.success) {
				setIsGeneratingContent(false);
			} else {
				let error = response && response.message ? response.message : 'Something went wrong.';

				//toast.error(error);
				console.error(error);

				//remove current process
				await RemoveRunningCampaignProcess(campaignId, {
					campaignId : campaignId,
					type       : 'Content'
				});
			}

			setIsGeneratingContent(false);
		}
	};

	const regenerateCampaignContent = async (e) => {
		e.preventDefault();

		if (campaignId) {
			if (!generateContentFormData.report) {
				toast.error('Please select the report.');
				return;
			}

			if (!generateContentFormData.rankingTypes || generateContentFormData.rankingTypes.length === 0) {
				toast.error('Please select the ranking type(s).');
				return;
			}

			if (!generateContentFormData.keywords || generateContentFormData.keywords.length === 0) {
				toast.error('Please select the keyword(s).');
				return;
			}

			if (
				!generateContentFormData.isProcessingContent &&
				!generateContentFormData.isProcessingHeadlines &&
				!generateContentFormData.isProcessingKeywordPostHeadlines &&
				!generateContentFormData.isProcessingKeywordPostContent &&
				!generateContentFormData.isProcessingGroupHeadlines &&
				!generateContentFormData.isProcessingGroupLocations
			) {
				toast.error('Please select items to process.');
				return;
			}

			setIsRegeneratingContent(true);

			let tempGenerateData = { ...generateContentFormData };

			if (industry) {
				tempGenerateData.headlineCommand = tempGenerateData.headlineCommand.replaceAll('{INDUSTRY}', industry);
				tempGenerateData.contentCommand = tempGenerateData.contentCommand.replaceAll('{INDUSTRY}', industry);
				tempGenerateData.keywordPostHeadlinePrompt = tempGenerateData.keywordPostHeadlinePrompt.replaceAll(
					'{INDUSTRY}',
					industry
				);
				tempGenerateData.keywordPostContentPrompt = tempGenerateData.keywordPostContentPrompt.replaceAll(
					'{INDUSTRY}',
					industry
				);
			}

			let data = {
				...generateContentFormData,
				id                        : campaignId,
				headlineCommand           : tempGenerateData.headlineCommand,
				contentCommand            : tempGenerateData.contentCommand,
				keywordPostHeadlinePrompt : tempGenerateData.keywordPostHeadlinePrompt,
				keywordPostContentPrompt  : tempGenerateData.keywordPostContentPrompt,
				keywordItems              : generateContentFormData.keywordItems
					? generateContentFormData.keywordItems.split('\n')
					: null
			};

			let newJob = await AddCampaignProcess(campaignId, {
				campaignId : campaignId,
				type       : 'Content Update',
				status     : 'Running',
				data       : JSON.stringify(data)
			});

			if (newJob && newJob.data) {
				data.processId = newJob.data.id;
			}

			// handleCloseDialog();
			setShowRunningJobs(true);
			setIsRegeneratingContent(false);

			const response = await RegenerateCampaignContent(campaignId, data);

			if (response.success) {
				//toast.success('Process started successfully.');

				setTimeout(function() {
					getCampaignRunningProcesses();
				}, 1000);
			} else {
				let error = response && response.message ? response.message : 'Something went wrong.';

				//toast.error(error);
				console.error(error);

				//remove current process
				await RemoveRunningCampaignProcess(campaignId, {
					campaignId : campaignId,
					type       : 'Content Update'
				});
			}

			setIsRegeneratingContent(false);
		}
	};

	const handleNewContentJob = () => {
		setShowRunningJobs(false);

		setGenerateContentFormData({
			...generateContentFormData,
			keywords              : [],
			rankingTypes          : [],
			keywordItems          : [],
			isProcessingContent   : true,
			isProcessingHeadlines : false
		});
		setIndustry(campaignDetails.listingType);
	};

	const handleGetKeywordItemsBySelectedKeyword = (e, keyword) => {
		if (e) e.preventDefault();

		if (keyword) {
			let tempGenerateContentFormData = { ...generateContentFormData };

			//load keyword items from keyword objects, if there is no any, get with Api
			let selectedKeywordItems;
			if (campaignKeywords && campaignKeywords.length > 0) {
				let selectedKeyword = campaignKeywords.find((x) => x.keyword && x.keyword.trim() === keyword.trim());

				if (selectedKeyword) {
					if (selectedKeyword.keywordItems) {
						selectedKeywordItems = selectedKeyword.keywordItems;
					}

					if (selectedKeyword.keywordPostHeadlinePrompt) {
						tempGenerateContentFormData.keywordPostHeadlinePrompt =
							selectedKeyword.keywordPostHeadlinePrompt;
					}

					if (selectedKeyword.keywordPostContentPrompt) {
						tempGenerateContentFormData.keywordPostContentPrompt = selectedKeyword.keywordPostContentPrompt;
					}
				}
			}

			if (!selectedKeywordItems || selectedKeywordItems.length === 0) {
				getKeywordItems(null, keyword);
			}

			setIndustry(keyword);

			//select All ranking
			let tempSelectedRankingTypes = tempGenerateContentFormData.rankingTypes || [];

			if (tempSelectedRankingTypes && !tempSelectedRankingTypes.includes('All')) {
				tempSelectedRankingTypes.push('All');
			}

			setPreselectRankingType('All');

			//check the keyword checkbox
			let tempSelectedKeywords = tempGenerateContentFormData.keywords || [];

			if (tempSelectedKeywords && !tempSelectedKeywords.includes(keyword)) {
				tempSelectedKeywords.push(keyword);
			}

			if (selectedKeywordItems && selectedKeywordItems.length > 0) {
				setKeywordItemsStr(selectedKeywordItems.split('|').join('\n'));
			}

			tempGenerateContentFormData.keywords = tempSelectedKeywords;
			tempGenerateContentFormData.rankingTypes = tempSelectedRankingTypes;

			setGenerateContentFormData(tempGenerateContentFormData);
		}
	};

	const regenerateCampaignContentTest = async (command = null) => {
		setIsRegeneratingContentTest(true);

		let tempCommand = command;

		if (industry) {
			tempCommand = tempCommand.replaceAll('{INDUSTRY}', industry);

			if (tempCommand.indexOf('{keyword}') > -1 && campaignKeywords && campaignKeywords.length > 0) {
				tempCommand = tempCommand.replaceAll('{keyword}', campaignKeywords[0].keyword);
			}
		}

		let data = {
			id             : campaignId,
			contentCommand : tempCommand,
			keywordItems   : generateContentFormData.keywordItems
				? generateContentFormData.keywordItems.split('\n')
				: null
		};

		let response = await RegenerateCampaignContentTest(campaignId, data);

		if (response && response.testResponseData) {
			let testResponseDataObj = JSON.parse(response.testResponseData);

			if (testResponseDataObj) {
				setRegenerateContentTestCommand(testResponseDataObj.command);
				setRegenerateContentTestResponse(testResponseDataObj.response);
			}
		}

		setIsRegeneratingContentTest(false);
	};

	const toggleRegenerateContentTestDialog = async (e, command = null) => {
		if (e) {
			e.preventDefault();
		}

		if (!showRegenerateContentTestDialog) {
			let tempCommand = command;

			if (!tempCommand || tempCommand.trim() === '') {
				toast.error('Please enter the command.');
				return;
			}

			if (!generateContentFormData.keywordItems || generateContentFormData.keywordItems.length === 0) {
				if (tempCommand.indexOf('{keywordItem}') > -1) {
					toast.error('Please enter the keyword items.');
					return;
				}
			}

			setRegenerateContentTestCommand();
			setRegenerateContentTestResponse();

			await regenerateCampaignContentTest(command);
		}

		setShowRegenerateContentTestDialog(!showRegenerateContentTestDialog);
	};

	const getElapsedTime = (startDate) => {
		startDate = new Date(startDate);
		let now = new Date();
		now = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));

		let momentStartDate = moment(startDate, 'DD/MM/YYYY HH:mm:ss');
		let momentNow = moment(now, 'DD/MM/YYYY HH:mm:ss');

		return getDuration(momentStartDate, momentNow);
	};

	return (
		<Fragment>
			<ModalDialog position="right-sidebar" className="text-start">
				<ModalDialogContent align="start">
					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							{showRunningJobs ? (
								<Fragment>
									<h5 className="mb-4">Running Content Jobs</h5>
									{isLoadingRunningContentJobs ? (
										<Spinner />
									) : (
										<table className="items-list campaigns-list table table-responsive table-bordered">
											<thead>
												<tr>
													<th className="type" scope="col">
														Type
													</th>
													<th className="date" scope="col">
														Elapsed Time
													</th>
													<th scope="col">Keywords</th>
													<th scope="col">Rankings</th>
												</tr>
											</thead>
											<tbody>
												{campaignRunningContentJobs && campaignRunningContentJobs.length > 0 ? (
													campaignRunningContentJobs.map((process, index) => {
														let elapsedTime = process.startDate
															? getElapsedTime(process.startDate)
															: '';

														let keywords = '';
														let rankingTypes = '';

														if (process.data) {
															let parsedData = JSON.parse(process.data);

															Object.keys(parsedData).map((parsedDataItemKey, index) => {
																let itemValue = parsedData[parsedDataItemKey];

																if (parsedDataItemKey === 'keywords') {
																	for (var itemValueKey in itemValue) {
																		if (itemValue.hasOwnProperty(itemValueKey)) {
																			keywords += itemValue[itemValueKey] + ', ';
																		}
																	}

																	if (keywords && keywords.length > 2) {
																		keywords = keywords.substring(
																			0,
																			keywords.length - 2
																		);
																	}
																}

																if (parsedDataItemKey === 'rankingTypes') {
																	for (var itemValueKey in itemValue) {
																		if (itemValue.hasOwnProperty(itemValueKey)) {
																			rankingTypes +=
																				itemValue[itemValueKey] + ', ';
																		}
																	}

																	if (rankingTypes && rankingTypes.length > 2) {
																		rankingTypes = rankingTypes.substring(
																			0,
																			rankingTypes.length - 2
																		);
																	}
																}
															});
														}

														return (
															<tr key={index}>
																<td>{process.type}</td>
																<td className="date">{elapsedTime}</td>
																<td>{keywords}</td>
																<td>{rankingTypes}</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td className="p-3" colSpan={8}>
															<i>No running jobs</i>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									)}
								</Fragment>
							) : (
								<div>
									<ul className="nav nav-tabs" id="tabGenerateContent" role="tablist">
										<li className="nav-item" role="presentation">
											<button
												className="nav-link active"
												id="content-tab"
												data-bs-toggle="tab"
												data-bs-target="#contact-tab-pane"
												type="button"
												role="tab"
												aria-controls="contact-tab-pane"
												aria-selected="true"
											>
												Content
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className="nav-link"
												id="prompts-tab"
												data-bs-toggle="tab"
												data-bs-target="#prompts-tab-pane"
												type="button"
												role="tab"
												aria-controls="prompts-tab-pane"
												aria-selected="false"
											>
												Prompts
											</button>
										</li>
									</ul>
									<div className="tab-content border" id="tabGenerateContentContent">
										<div
											className="tab-pane p-3 fade show active"
											id="contact-tab-pane"
											role="tabpanel"
											aria-labelledby="content-tab"
										>
											<div className="col-12 mb-3 text-center">
												<button
													type="button"
													className="btn btn-primary"
													onClick={generateCampaignContent}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												>
													<span>Generate Website Content</span>
												</button>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="industry" className="form-label">
													Industry:
												</label>
												<input
													type="text"
													className="form-control"
													name="industry"
													value={industry}
													onChange={(e) => setIndustry(e.target.value)}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordItems" className="form-label">
													Keyword Items:
												</label>
												<textarea
													className="form-control"
													name="keywordItems"
													value={generateContentFormData.keywordItems || ''}
													onChange={handleGenerateContentFormDataChange}
													rows={12}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
													wrap="off"
												/>
												<div>
													<button
														className="btn btn-link ps-0"
														type="button"
														onClick={(e) => getKeywordItems(e, industry)}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													>
														{isGettingKeywordItems ? (
															<Fragment>
																<span className="spinner-border m-0 me-2" />
																<span>Getting Keywords...</span>
															</Fragment>
														) : (
															<span>Get Keywords</span>
														)}
													</button>
												</div>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordItems" className="form-label">
													Select Report:
												</label>
												<select
													className="form-select"
													name="report"
													onChange={handleGenerateContentFormDataChange}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												>
													{campaignReports &&
														campaignReports.map((report) => (
															<option value={report.id} key={report.id}>
																{UnixTimestampToDateTime(report.dateUnix)}
															</option>
														))}
												</select>
											</div>
											<div className="col-12 mb-3">
												<KeywordMapStatsV4
													keywordData={{ data: null }}
													showValue={false}
													selectable={true}
													preselectValue={preselectRankingType}
													onItemSelect={handleRankingTypeSelect}
													//mobileReportView={mobileReportView}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywords" className="form-label">
													Keywords:
												</label>
												{campaignKeywords &&
													campaignKeywords.length > 0 &&
													campaignKeywords.map((keyword, index) => (
														<div key={keyword.id}>
															<input
																type="checkbox"
																className="form-check-input"
																name={`chk_Keyword_${keyword.keyword}`}
																id={`chk_Keyword_${keyword.id}`}
																onChange={handleGenerateContentFormDataChange}
																checked={
																	generateContentFormData &&
																	generateContentFormData.keywords &&
																	generateContentFormData.keywords.includes(
																		keyword.keyword
																	)
																}
																disabled={
																	isGeneratingContent ||
																	isRegeneratingContent ||
																	isGettingKeywordItems
																}
															/>
															<label
																htmlFor={`chk_Keyword_${keyword.id}`}
																className="form-check-label ms-2 w-auto"
															>
																{keyword.keyword}
															</label>
															<button
																className="btn btn-link p-0 table-link-button ms-2 mb-1"
																title="Get keyword items for this keyword"
																onClick={(e) =>
																	handleGetKeywordItemsBySelectedKeyword(
																		e,
																		keyword.keyword
																	)}
															>
																<i className="fa-regular fa-copy" />
															</button>
														</div>
													))}
											</div>
											<div className="col-12 mb-3">
												<hr />
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="isProcessingGroupHeadlines" className="form-label">
													Process Following Items:
												</label>
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														name="isProcessingGroupHeadlines"
														id="isProcessingGroupHeadlines"
														defaultChecked={
															generateContentFormData.isProcessingGroupHeadlines
														}
														onChange={handleGenerateContentFormDataChange}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													/>
													<label
														htmlFor="isProcessingGroupHeadlines"
														className="form-check-label"
													>
														Group Headline
													</label>
												</div>
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														name="isProcessingGroupLocations"
														id="isProcessingGroupLocations"
														defaultChecked={
															generateContentFormData.isProcessingGroupLocations
														}
														onChange={handleGenerateContentFormDataChange}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													/>
													<label
														htmlFor="isProcessingGroupLocations"
														className="form-check-label"
													>
														Group Location
													</label>
												</div>
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														name="isProcessingHeadlines"
														id="isProcessingHeadlines"
														defaultChecked={generateContentFormData.isProcessingHeadlines}
														onChange={handleGenerateContentFormDataChange}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													/>
													<label htmlFor="isProcessingHeadlines" className="form-check-label">
														Keyword Headline
													</label>
												</div>
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														name="isProcessingContent"
														id="isProcessingContent"
														defaultChecked={generateContentFormData.isProcessingContent}
														onChange={handleGenerateContentFormDataChange}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													/>
													<label htmlFor="isProcessingContent" className="form-check-label">
														Keyword Content
													</label>
												</div>
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														name="isProcessingKeywordPostHeadlines"
														id="isProcessingKeywordPostHeadlines"
														defaultChecked={
															generateContentFormData.isProcessingKeywordPostHeadlines
														}
														onChange={handleGenerateContentFormDataChange}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													/>
													<label
														htmlFor="isProcessingKeywordPostHeadlines"
														className="form-check-label"
													>
														Keyword Post Headline
													</label>
												</div>
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														name="isProcessingKeywordPostContent"
														id="isProcessingKeywordPostContent"
														defaultChecked={
															generateContentFormData.isProcessingKeywordPostContent
														}
														onChange={handleGenerateContentFormDataChange}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													/>
													<label
														htmlFor="isProcessingKeywordPostContent"
														className="form-check-label"
													>
														Keyword Post Content
													</label>
												</div>
											</div>
										</div>
										<div
											className="tab-pane p-3 fade"
											id="prompts-tab-pane"
											role="tabpanel"
											aria-labelledby="prompts-tab"
										>
											<div className="col-12 mb-3">
												<label htmlFor="headlineCommand" className="form-label">
													Keyword Headline Prompt:
												</label>
												<textarea
													className="form-control"
													name="headlineCommand"
													value={generateContentFormData.headlineCommand || ''}
													onChange={handleGenerateContentFormDataChange}
													rows={3}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												/>
												<div>
													<button
														className="btn btn-link ps-0 pt-0"
														type="button"
														onClick={(e) =>
															toggleRegenerateContentTestDialog(
																e,
																generateContentFormData.headlineCommand
															)}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													>
														Test
													</button>
												</div>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="contentCommand" className="form-label">
													Keyword Content Prompt:
												</label>
												<textarea
													className="form-control"
													name="contentCommand"
													value={generateContentFormData.contentCommand || ''}
													onChange={handleGenerateContentFormDataChange}
													rows={3}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												/>
												<div>
													<button
														className="btn btn-link ps-0 pt-0"
														type="button"
														onClick={(e) =>
															toggleRegenerateContentTestDialog(
																e,
																generateContentFormData.contentCommand
															)}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													>
														Test
													</button>
												</div>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordPostHeadlinePrompt" className="form-label">
													Keyword Post Headline Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordPostHeadlinePrompt"
													value={generateContentFormData.keywordPostHeadlinePrompt || ''}
													onChange={handleGenerateContentFormDataChange}
													rows={3}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												/>
												<div>
													<button
														className="btn btn-link ps-0 pt-0"
														type="button"
														onClick={(e) =>
															toggleRegenerateContentTestDialog(
																e,
																generateContentFormData.keywordPostHeadlinePrompt
															)}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													>
														Test
													</button>
												</div>
											</div>
											<div className="col-12 mb-0">
												<label htmlFor="keywordPostContentPrompt" className="form-label">
													Keyword Post Content Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordPostContentPrompt"
													value={generateContentFormData.keywordPostContentPrompt || ''}
													onChange={handleGenerateContentFormDataChange}
													rows={3}
													disabled={
														isGeneratingContent ||
														isRegeneratingContent ||
														isGettingKeywordItems
													}
												/>
												<div>
													<button
														className="btn btn-link ps-0 pt-0"
														type="button"
														onClick={(e) =>
															toggleRegenerateContentTestDialog(
																e,
																generateContentFormData.keywordPostContentPrompt
															)}
														disabled={
															isGeneratingContent ||
															isRegeneratingContent ||
															isGettingKeywordItems
														}
													>
														Test
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</Fragment>
					)}
				</ModalDialogContent>
				<ModalDialogButtons>
					{showRunningJobs ? (
						<Fragment>
							<button className="btn btn-primary" type="button" onClick={handleNewContentJob}>
								<span>New Content Job</span>
							</button>
							<button className="btn btn-outline-primary me-2" type="button" onClick={handleCloseDialog}>
								Cancel
							</button>
						</Fragment>
					) : (
						<Fragment>
							<button className="btn btn-primary" type="button" onClick={regenerateCampaignContent}>
								{isRegeneratingContent ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Regenerating Content...</span>
									</Fragment>
								) : (
									<span>Regenerate Content</span>
								)}
							</button>
							<button className="btn btn-outline-primary me-2" type="button" onClick={handleCloseDialog}>
								Cancel
							</button>
						</Fragment>
					)}
				</ModalDialogButtons>
			</ModalDialog>

			{showRegenerateContentTestDialog && (
				<ModalDialog position="right-sidebar" className="text-start">
					<ModalDialogContent align="start">
						<div className="row">
							{/* {isRegeneratingContentTest ? (
                                <Spinner />
                            ) : ( */}
							<Fragment>
								<div className="col-12 mb-3">
									<label htmlFor="regenerateContentTestCommand" className="form-label">
										Command:
									</label>
									<textarea
										className="form-control p-2"
										name="regenerateContentTestCommand"
										value={regenerateContentTestCommand || ''}
										onChange={(e) => setRegenerateContentTestCommand(e.target.value)}
										rows={5}
										readOnly={true}
										disabled={isRegeneratingContentTest}
									/>
								</div>
								<div className="col-12">
									<label htmlFor="response" className="form-label">
										Response:
									</label>
									<div
										className="border rounded p-2"
										dangerouslySetInnerHTML={{ __html: regenerateContentTestResponse }}
										style={isRegeneratingContentTest ? { backgroundColor: '#e9ecef' } : {}}
									/>
									{/* <textarea
                                        className="form-control"
                                        name="response"
                                        value={regenerateContentTestResponse || ''}
                                        onChange={(e) => setRegenerateContentTestResponse(e.target.value)}
                                        rows={10}
                                        disabled={true}
                                    /> */}
								</div>
							</Fragment>
							{/* )} */}
						</div>
					</ModalDialogContent>
					<ModalDialogButtons>
						<button
							className="btn btn-primary"
							type="button"
							onClick={() => regenerateCampaignContentTest(regenerateContentTestCommand)}
							disabled={isRegeneratingContentTest}
						>
							{isRegeneratingContentTest ? (
								<Fragment>
									<span className="spinner-border m-0 me-2" />
									<span>Getting response...</span>
								</Fragment>
							) : (
								<span>Refresh</span>
							)}
						</button>
						<button
							className="btn btn-outline-primary me-2"
							type="button"
							onClick={(e) => toggleRegenerateContentTestDialog(e)}
						>
							Cancel
						</button>
					</ModalDialogButtons>
				</ModalDialog>
			)}
		</Fragment>
	);
}

export default GenerateContentDialog;
