import { Fragment, useEffect, useState } from 'react';
import { GetCities, GetLocations, GetLocationsV2 } from '../../Services';
import Spinner from '../spinner/Spinner';
import './LocationsGeneratorV2.css';
import { toast } from 'react-toastify';

const LocationsGeneratorV2 = ({
	location,
	onLocationsUpdate
}) => {
	const [isEditMode, setIsEditMode] = useState(true);
	const [isGenerating, setIsGenerating] = useState(false);
	const [generatedLocations, setGeneratedLocations] = useState();
	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');
	const [radius, setRadius] = useState('30');
	const [locations, setLocations] = useState();
	const [locationsString, setLocationsString] = useState();
	const [distance, setDistance] = useState('30');

	useEffect(
		() => {
			if (location) {
				setLat(location.lat.toString());
				setLng(location.lng.toString());
			}
		},
		[location]
	);

	useEffect(
		() => {
			console.log('generatedLocations', generatedLocations);
			setIsEditMode(
				!generatedLocations ||
				!generatedLocations.cities ||
				generatedLocations.cities.length === 0
			);
		},
		[generatedLocations]
	);

	const toggleEditMode = () => {
		setIsEditMode((prev) => !prev);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (lat.trim() === '' || lng.trim() === '' || radius.trim() === '') {
			return;
		}

		setIsGenerating(true);

		let data = {
			lat: lat,
			lng: lng,
			radiusMiles: radius,
			DistanceBetweenPoints: distance
		};

		// const response = await GetLocationsV2(data);
		const response = await GetCities(data);

		console.log(response);

		if (response.success) {
			if (onLocationsUpdate) {
				onLocationsUpdate(response.cities);
				// onLocationsUpdate([
				// 	{
				// 		name      : 'Sarasota',
				// 		state     : 'Florida',
				// 		stateAbbr : 'FL'
				// 	},
				// 	{
				// 		name      : 'Bradenton',
				// 		state     : 'Florida',
				// 		stateAbbr : 'FL'
				// 	},
				// 	{
				// 		name      : 'Tampa',
				// 		state     : 'Florida',
				// 		stateAbbr : 'FL'
				// 	}
				// ]);
			}
		}

		// setLocationsString(response);
		setGeneratedLocations(response.cities);

		let tempLocationsString = '';
		if (response && response.cities && response.cities.length > 0) {
			response.cities.forEach((city) => {
				tempLocationsString += `${city.city}, ${city.stateName}, ${city.stateAbbr}\r\n`;
			});
		}

		setLocationsString(tempLocationsString);

		setIsGenerating(false);
	};

	const handleCopyToClipboard = () => {
		let copyText = document.querySelector('.clipboard');
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand('copy');

		toast.success('Copied to clipboard!');
	};

	if (isGenerating) {
		return (
			<div className="locations-generator">
				<Spinner />
			</div>
		);
	}

	return (
		<div className="locations-generator">
			<h5 className="mb-3">Locations Generator</h5>
			<Fragment>
				<div className='location-settings'>
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<label htmlFor="latitude" className="form-label">
								Latitude:
							</label>
							<input
								type="text"
								className="form-control"
								id="latitude"
								placeholder="Latitude"
								value={lat}
								onChange={(e) => setLat(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="longitude" className="form-label">
								Longitude:
							</label>
							<input
								type="text"
								className="form-control"
								id="longitude"
								placeholder="Longitude"
								value={lng}
								onChange={(e) => setLng(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="radius" className="form-label">
								Radius (miles):
							</label>
							<input
								type="text"
								className="form-control"
								id="radius"
								placeholder="Radius"
								value={radius}
								onChange={(e) => setRadius(e.target.value)}
							/>
						</div>
						{/* <div className="mb-3">
							<label htmlFor="radius" className="form-label">
								Distance between points (miles):
							</label>
							<input
								type="text"
								className="form-control"
								id="distance"
								placeholder="Distance between points"
								value={distance}
								onChange={(e) => setDistance(e.target.value)}
							/>
						</div> */}
						<div className="buttons-container">
							<button type="submit" className="btn btn-primary">
								<span>Generate Locations</span>
							</button>
						</div>
					</form>
				</div>
			</Fragment>
			<Fragment>
				{generatedLocations &&
					generatedLocations.length > 0 && (
						<Fragment>
							<div className='results-container'>
								<button type='button' className='btn btn-outline-primary' onClick={handleCopyToClipboard}>
									<span>Copy to Clipboard</span>
								</button>

								<div className='count text-muted'>
									<span>Count: {generatedLocations.length}</span>
								</div>
							</div>
							<table className="table">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Name</th>
										<th scope="col">State</th>
										<th scope="col">State Abbr.</th>
									</tr>
								</thead>
								<tbody>
									{generatedLocations.map(
										(generatedLocation, index) => (
											<tr key={index}>
												<th scope="row">{index + 1}</th>
												<td>{generatedLocation.city}</td>
												<td>
													{generatedLocation.stateName}
												</td>
												<td>
													{generatedLocation.stateAbbr}
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>

							<textarea className='clipboard' value={locationsString} readOnly></textarea>
						</Fragment>
					)}
			</Fragment>
		</div>
	);
};

export default LocationsGeneratorV2;
