import { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	GenerateCampaignTestContent,
	GetCampaign,
	GetCampaignDefaultSettings,
	GetCampaignKeywordGroups,
	GetCampaignKeywords,
	GetKeywordItems,
	UpdateCampaign,
	UpdateCampaignDefaultSettings,
	UpdateCampaignKeywordGroups,
	UpdateCampaignKeywords,
	UpdateCampaignStatus
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignKeywordsScreen.css';
import { toast } from 'react-toastify';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import DndCard from '../../../components/dragAndDrop/DragAndDropCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const CampaignKeywordsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingDialog, setIsLoadingDialog ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ keywords, setKeywords ] = useState([]);
	const [ keywordsForList, setKeywordsForList ] = useState([]);
	const [ keywordGroups, setKeywordGroups ] = useState([]);
	const [ keywordGroupsForList, setKeywordGroupsForList ] = useState([]);
	const [ keywordGroupCheckedKeywords, setKeywordGroupCheckedKeywords ] = useState();
	const [ keywordsFormData, setKeywordsFormData ] = useState({});
	const [ keywordGroupsFormData, setKeywordGroupsFormData ] = useState({});
	const [ defaultSettingsData, setDefaultSettingsData ] = useState({});
	const [ showKeywordsDialog, setShowKeywordsDialog ] = useState(false);
	const [ showKeywordGroupsDialog, setShowKeywordGroupsDialog ] = useState(false);
	const [ showDefaultSettingsDialog, setShowDefaultSettingsDialog ] = useState(false);
	const [ defaultSettingsUpdated, setDefaultSettingsUpdated ] = useState(false);
	const [ isGeneratingContentTest, setIsGeneratingContentTest ] = useState(false);
	const [ isContentTest, setIsContentTest ] = useState(false);
	const [ contentTestResponse, setContentTestResponse ] = useState('');
	const [ editKeywordIndex, setEditKeywordIndex ] = useState();
	const [ editKeywordGroupIndex, setEditKeywordGroupIndex ] = useState();
	const contentTestResponseRef = useRef();
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const [ appendNearMe, setAppendNearMe ] = useState(false);
	const [ useNearInsteadOfIn, setUseNearInsteadOfIn ] = useState(false);
	const [ isGettingKeywordItems, setIsGettingKeywordItems ] = useState(false);
	const [ keywordItemsStr, setKeywordItemsStr ] = useState();
	const [ selectedKeywordsForGroup, setSelectedKeywordsForGroup ] = useState([]);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignKeywords();
				getCampaignKeywordGroups();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (keywords && keywords.length) {
				let tempKeywordsForList = keywords.filter((x) => x.state !== 'removed');
				setKeywordsForList(tempKeywordsForList);
				setKeywordsItemsIndex(tempKeywordsForList);
			}
		},
		[ keywords ]
	);

	useEffect(
		() => {
			if (keywordGroups && keywordGroups.length) {
				let tempKeywordGroupsForList = keywordGroups.filter((x) => x.state !== 'removed');
				setKeywordGroupsForList(tempKeywordGroupsForList);
				setKeywordGroupsItemsIndex(tempKeywordGroupsForList);
			}
		},
		[ keywordGroups ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;

			setAppendNearMe(response.addNearMeInReports);
			setUseNearInsteadOfIn(response.useNearInsteadOfIn);
			setCampaign(response);
		}
	};

	const getCampaignKeywords = async () => {
		setIsLoading(true);

		const response = await GetCampaignKeywords(campaignId);

		if (response) {
			let tempKeywords = response;

			tempKeywords = tempKeywords.sort(function(a, b) {
				return a.orderIndex - b.orderIndex;
			});

			for (let i = 0; i < tempKeywords.length; i++) {
				tempKeywords[i].index = i;
				tempKeywords[i].state = '';
			}

			setKeywords(tempKeywords);
		}

		setIsLoading(false);
	};

	const getCampaignKeywordGroups = async () => {
		setIsLoading(true);

		const response = await GetCampaignKeywordGroups(campaignId);

		if (response && response.length) {
			let tempKeywordGroups = response;

			tempKeywordGroups = tempKeywordGroups.sort(function(a, b) {
				return a.orderIndex - b.orderIndex;
			});

			for (let i = 0; i < tempKeywordGroups.length; i++) {
				tempKeywordGroups[i].index = i;
				tempKeywordGroups[i].state = '';
				tempKeywordGroups[i].groupHeadlinePrompt = tempKeywordGroups[i].headlinePrompt;
				tempKeywordGroups[i].groupLocationPrompt = tempKeywordGroups[i].locationPrompt;
			}

			setKeywordGroups(tempKeywordGroups);
		}

		setIsLoading(false);
	};

	const getKeywordItems = async (e, keyword) => {
		if (e) e.preventDefault();

		if (
			!defaultSettingsData.keywordItemsContentPrompt ||
			defaultSettingsData.keywordItemsContentPrompt.trim() === ''
		) {
			toast.error('Please set the keyword items command first.');
			return;
		}

		if (!keyword || keyword.trim() === '') {
			toast.error('Please set the keyword first.');
			return;
		}

		setIsGettingKeywordItems(true);

		let tempInstructions = defaultSettingsData.keywordItemsContentPrompt;

		if (keyword) {
			tempInstructions = tempInstructions.replaceAll('{INDUSTRY}', keyword);
		}

		let data = {
			instructions : tempInstructions
		};

		const response = await GetKeywordItems(campaignId, data);

		if (response.success && response.items) {
			let items = [];

			//remove numbers from response items
			for (let i = 0; i < response.items.length; i++) {
				let item = response.items[i];

				item = item.replace(/\d\.\s+|[a-z]\)\s+|•\s+|[A-Z]\.\s+|[IVX]+\.\s+/g, ''); //remove bullets
				item = item.replace(/^-+/, ''); //remove hyphen
				item = item.replace(/^[0-9]+/, ''); //remove starting number
				item = item.trim();

				//add to list if it doesn't exist
				let itemFound = items.find((x) => x.toString().toLowerCase() === item.toString().toLowerCase());

				if (!itemFound || itemFound === null) {
					items.push(item);
				}
			}

			setKeywordItemsStr(items.join('\n'));
		}

		setIsGettingKeywordItems(false);
	};

	useEffect(
		() => {
			if (keywordItemsStr) {
				setKeywordsFormData({
					...keywordsFormData,
					keywordItems : keywordItemsStr
				});
			}
		},
		[ keywordItemsStr ]
	);

	const handleKeywordsFormDataChange = (e) => {
		setKeywordsFormData({
			...keywordsFormData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveKeywordItem = (e) => {
		e.preventDefault();

		if (!keywordsFormData.keyword || keywordsFormData.keyword.trim() === '') {
			toast.error('Keyword is required!');
			return;
		}

		if (!keywordsFormData.keywordItems || keywordsFormData.keywordItems.trim() === '') {
			toast.error('Keyword items field is required!');
			return;
		}

		let tempKeywords = [ ...keywords ];

		if (editKeywordIndex !== undefined) {
			//edit
			let currentKeyword = tempKeywords
				.filter((x) => x.state !== 'removed')
				.find((x) => x.index === editKeywordIndex);

			if (currentKeyword) {
				currentKeyword.keyword = keywordsFormData.keyword;
				currentKeyword.headlinePrompt = keywordsFormData.headlinePrompt;
				currentKeyword.contentPrompt = keywordsFormData.contentPrompt;
				currentKeyword.keywordPostHeadlinePrompt = keywordsFormData.keywordPostHeadlinePrompt;
				currentKeyword.keywordPostContentPrompt = keywordsFormData.keywordPostContentPrompt;
				currentKeyword.keywordItems = keywordsFormData.keywordItems.replaceAll('\n', '|');
				currentKeyword.keywordVariations = keywordsFormData.keywordVariations;
				currentKeyword.state = currentKeyword.state === 'added' ? 'added' : 'updated';
			}
		} else {
			//new
			tempKeywords.push({
				...keywordsFormData,
				index        : tempKeywords.length,
				orderIndex   : tempKeywords.length + 1,
				id           : null,
				keywordItems : keywordsFormData.keywordItems.replaceAll('\n', '|'),
				state        : 'added'
			});
		}

		setKeywordsItemsIndex(tempKeywords);

		setKeywords(tempKeywords);
		setKeywordsFormData({});
		setShowKeywordsDialog(false);
	};

	const handleKeywordsSubmit = async (redirect = false) => {
		//e.preventDefault();

		let tempKeywords = [ ...keywords ];

		if (!tempKeywords || tempKeywords.length === 0) {
			toast.error('Please add keyword first');
			return;
		}

		setIsSubmitting(true);

		let data = {
			id       : campaignId,
			keywords : []
		};

		for (let i = 0; i < tempKeywords.length; i++) {
			data.keywords.push({
				...tempKeywords[i]
			});
		}

		const response = await UpdateCampaignKeywords(campaignId, data);

		if (response.success) {
			saveCampaign();

			if (redirect) {
				navigate(`/setup/${campaignId}/services`);
			} else {
				toast.success('Settings saved.');
				getCampaignKeywords();
			}
		}

		setIsSubmitting(false);
	};

	const handleToggleKeywordsDialog = async (index) => {
		setShowKeywordsDialog(!showKeywordsDialog);

		if (!showKeywordsDialog) {
			var defaultSettings = await GetCampaignDefaultSettings();
			setDefaultSettingsData(defaultSettings.data);

			if (index !== undefined) {
				//edit
				setEditKeywordIndex(index);
				let currentKeyword = keywords.filter((x) => x.state !== 'removed').find((x) => x.index === index);

				if (currentKeyword) {
					if (currentKeyword.keywordItems) {
						currentKeyword.keywordItems = currentKeyword.keywordItems.replaceAll('|', '\n');
					}

					if (defaultSettings && defaultSettings.data && defaultSettings.data) {
						// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);

						//for backward compatibility
						if (!currentKeyword.keywordPostHeadlinePrompt) {
							currentKeyword.keywordPostHeadlinePrompt = defaultSettings.data.keywordPostHeadlinePrompt;
						}
						if (!currentKeyword.keywordPostContentPrompt) {
							currentKeyword.keywordPostContentPrompt = defaultSettings.data.keywordPostContentPrompt;
						}
					}

					setKeywordsFormData(currentKeyword);
				}
			} else {
				//new
				setEditKeywordIndex();

				let tempFormData = { ...keywordsFormData };

				if (tempFormData.keywordItems) {
					tempFormData.keywordItems = tempFormData.keywordItems.replaceAll('|', '\n');
				}

				if (defaultSettings && defaultSettings.data && defaultSettings.data) {
					// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);

					if (!tempFormData.headlinePrompt) {
						tempFormData.headlinePrompt = defaultSettings.data.keywordsHeadlinePrompt;
					}
					if (!tempFormData.contentPrompt) {
						tempFormData.contentPrompt = defaultSettings.data.keywordsContentPrompt;
					}
					if (!tempFormData.keywordPostHeadlinePrompt) {
						tempFormData.keywordPostHeadlinePrompt = defaultSettings.data.keywordPostHeadlinePrompt;
					}
					if (!tempFormData.keywordPostContentPrompt) {
						tempFormData.keywordPostContentPrompt = defaultSettings.data.keywordPostContentPrompt;
					}
				}
				// }

				setKeywordsFormData(tempFormData);
			}
		} else {
			setKeywordsFormData({});
		}
	};

	useEffect(
		() => {
			if (showKeywordsDialog && defaultSettingsData) {
				if (isLoadingDialog) {
					setIsLoadingDialog(false);
				}
			}
		},
		[ showKeywordsDialog, defaultSettingsData ]
	);

	const handleToggleDefaultSettingsDialog = async () => {
		setShowDefaultSettingsDialog(!showDefaultSettingsDialog);

		if (!showDefaultSettingsDialog) {
			let response = await GetCampaignDefaultSettings();

			if (response && response.data) {
				setDefaultSettingsData(response.data);
			}
		} else {
			if (defaultSettingsUpdated && editKeywordIndex === undefined) {
				var defaultSettings = await GetCampaignDefaultSettings();

				if (defaultSettings && defaultSettings.data && defaultSettings.data) {
					// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);

					setKeywordsFormData({
						...keywordsFormData,
						headlinePrompt            : defaultSettings.data.keywordsHeadlinePrompt,
						contentPrompt             : defaultSettings.data.keywordsContentPrompt,
						keywordPostHeadlinePrompt : defaultSettings.data.keywordPostHeadlinePrompt,
						keywordPostContentPrompt  : defaultSettings.data.keywordPostContentPrompt
					});

					setKeywordGroupsFormData({
						...keywordGroupsFormData,
						groupHeadlinePrompt : defaultSettings.data.keywordGroupHeadlinePrompt,
						groupLocationPrompt : defaultSettings.data.locationContentPrompt
					});
				}
			}
		}
	};

	const handleDefaultSettingsChange = async (e) => {
		setDefaultSettingsData({
			...defaultSettingsData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveDefaultSettings = async () => {
		let response = await UpdateCampaignDefaultSettings(defaultSettingsData);

		if (response) {
			setDefaultSettingsUpdated(true);
			handleToggleDefaultSettingsDialog();
			toast.success('Default settings updated successfully.');
		} else {
			toast.error(response.message);
		}
	};

	const generateContentTestResponse = async (isContent = true) => {
		setIsGeneratingContentTest(true);

		let data = {
			id            : campaignId,
			keyword       : keywordsFormData.keyword,
			contentPrompt : isContent ? keywordsFormData.contentPrompt : keywordsFormData.headlinePrompt,
			keywordItems  : keywordsFormData.keywordItems.replaceAll('\n', '|')
		};

		const response = await GenerateCampaignTestContent(campaignId, data);

		if (response.success && response.response) {
			setContentTestResponse(response.response);
		} else {
			toast.error(response.message);
			console.error(response);
		}

		setIsGeneratingContentTest(false);
	};

	const toggleTestContentResponse = async (isContent = true) => {
		if (!isContentTest) {
			if (!keywordsFormData.keyword || keywordsFormData.keyword.trim() === '') {
				toast.error('Please set keyword first');
				return;
			}

			if (isContent) {
				if (!keywordsFormData.contentPrompt || keywordsFormData.contentPrompt.trim() === '') {
					toast.error('Please set content prompt first');
					return;
				}
			} else {
				if (!keywordsFormData.headlinePrompt || keywordsFormData.headlinePrompt.trim() === '') {
					toast.error('Please set headline prompt first');
					return;
				}
			}

			setIsContentTest(!isContentTest);

			await generateContentTestResponse(isContent);
		} else {
			setIsContentTest(!isContentTest);
		}
	};

	const handleSetPromptAsDefault = async (isContent = true) => {
		//e.preventDefault();

		let tempCampaign = campaign;

		if (isContent) {
			tempCampaign.defaultContentPrompt = keywordsFormData.contentPrompt;
		} else {
			tempCampaign.defaultHeadlinePrompt = keywordsFormData.headlinePrompt;
		}

		const response = await UpdateCampaign(campaignId, tempCampaign);

		if (response.success) {
			setCampaign(tempCampaign);
			toast.success('Value set as default successfully');
		}
	};

	const handleKeywordItemMove = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = keywords[dragIndex];
			const hoverItem = keywords[hoverIndex];

			// Swap places of dragItem and hoverItem in the keywords array
			setKeywords((keywords) => {
				const updatedKeywords = [ ...keywords ];
				updatedKeywords[dragIndex] = hoverItem;
				updatedKeywords[hoverIndex] = dragItem;

				//update index
				updatedKeywords[hoverIndex].index = hoverIndex;
				updatedKeywords[dragIndex].index = dragIndex;

				//update state
				updatedKeywords[hoverIndex].state = 'updated';
				updatedKeywords[dragIndex].state = 'updated';

				setKeywordsItemsIndex(updatedKeywords);

				return updatedKeywords;
			});
		},
		[ keywords ]
	);

	const handleKeywordItemRemove = useCallback(
		(index) => {
			if (window.confirm('Are you sure that you want to remove this keyword?')) {
				setKeywords((keywords) => {
					const item = keywords[index];
					const updatedKeywords = [ ...keywords ];

					if (item.state === 'added') {
						delete updatedKeywords[index];
					} else {
						updatedKeywords[index].state = 'removed';
					}

					setKeywordsItemsIndex(updatedKeywords);

					return updatedKeywords;
				});
			}
		},
		[ keywords ]
	);

	const setKeywordsItemsIndex = (keywordsArray) => {
		if (keywordsArray && keywordsArray.length) {
			for (let i = 0; i < keywordsArray.length; i++) {
				keywordsArray[i].index = i;
				keywordsArray[i].orderIndex = i + 1;
			}
		}
	};

	//keyword groups --------------
	const handleKeywordGroupsFormDataChange = (e) => {
		setKeywordGroupsFormData({
			...keywordGroupsFormData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveKeywordGroupItem = (e) => {
		e.preventDefault();

		if (!keywordGroupsFormData.name || keywordGroupsFormData.name.trim() === '') {
			toast.error('Name is required!');
			return;
		}

		if (!keywordGroupsFormData.keywords || keywordGroupsFormData.keywords.length === 0) {
			toast.error('At least one keyword is required!');
			return;
		}

		let tempKeywordGroups = [ ...keywordGroups ];

		if (editKeywordGroupIndex !== undefined) {
			//edit
			let currentKeywordGroup = tempKeywordGroups
				.filter((x) => x.state !== 'removed')
				.find((x) => x.index === editKeywordGroupIndex);

			if (currentKeywordGroup) {
				currentKeywordGroup.name = keywordGroupsFormData.name;
				currentKeywordGroup.groupHeadlinePrompt = keywordGroupsFormData.groupHeadlinePrompt;
				currentKeywordGroup.groupLocationPrompt = keywordGroupsFormData.groupLocationPrompt;
				currentKeywordGroup.keywords = keywordGroupsFormData.keywords;
				currentKeywordGroup.state = currentKeywordGroup.state === 'added' ? 'added' : 'updated';
			}
		} else {
			//new
			tempKeywordGroups.push({
				index               : tempKeywordGroups.length,
				orderIndex          : tempKeywordGroups.length + 1,
				id                  : null,
				name                : keywordGroupsFormData.name,
				groupHeadlinePrompt : keywordGroupsFormData.groupHeadlinePrompt,
				groupLocationPrompt : keywordGroupsFormData.groupLocationPrompt,
				keywords            : keywordGroupsFormData.keywords,
				state               : 'added'
			});
		}

		setKeywordGroupsItemsIndex(tempKeywordGroups);

		setKeywordGroups(tempKeywordGroups);
		setKeywordGroupsFormData({});
		setShowKeywordGroupsDialog(false);
	};

	const handleKeywordGroupsSubmit = async (redirect = false) => {
		//e.preventDefault();

		let tempKeywordGroups = [ ...keywordGroups ];

		if (!tempKeywordGroups || tempKeywordGroups.length === 0) {
			toast.error('Please add keyword group first');
			return;
		}

		setIsSubmitting(true);

		let data = {
			id            : campaignId,
			keywordGroups : []
		};

		for (let i = 0; i < tempKeywordGroups.length; i++) {
			data.keywordGroups.push({
				id             : tempKeywordGroups[i].id,
				name           : tempKeywordGroups[i].name,
				headlinePrompt : tempKeywordGroups[i].groupHeadlinePrompt,
				locationPrompt : tempKeywordGroups[i].groupLocationPrompt,
				orderIndex     : tempKeywordGroups[i].orderIndex,
				state          : tempKeywordGroups[i].state,
				keywords       : tempKeywordGroups[i].keywords //selectedKeywordsForGroup
			});
		}

		const response = await UpdateCampaignKeywordGroups(campaignId, data);

		if (response.success) {
			if (redirect) {
				navigate(`/setup/${campaignId}/services`);
			} else {
				toast.success('Settings saved.');
				getCampaignKeywordGroups();
			}
		}

		setIsSubmitting(false);
	};

	const handleToggleKeywordGroupsDialog = async (index) => {
		setShowKeywordGroupsDialog(!showKeywordGroupsDialog);

		if (!showKeywordGroupsDialog) {
			var defaultSettings = await GetCampaignDefaultSettings();
			setDefaultSettingsData(defaultSettings.data);

			if (index !== undefined) {
				//edit
				setEditKeywordGroupIndex(index);

				let currentKeywordGroup = keywordGroups
					.filter((x) => x.state !== 'removed')
					.find((x) => x.index === index);

				if (currentKeywordGroup) {
					setKeywordGroupsFormData(currentKeywordGroup);
				}
			} else {
				//new
				setEditKeywordGroupIndex();

				let tempFormData = { ...keywordGroupsFormData };

				if (defaultSettings && defaultSettings.data && defaultSettings.data) {
					if (!tempFormData.groupHeadlinePrompt) {
						tempFormData.groupHeadlinePrompt = defaultSettings.data.keywordGroupHeadlinePrompt;
					}
					if (!tempFormData.groupLocationPrompt) {
						tempFormData.groupLocationPrompt = defaultSettings.data.locationContentPrompt;
					}
				}

				setKeywordGroupsFormData(tempFormData);
			}
		} else {
			setKeywordGroupsFormData({});
		}
	};

	useEffect(
		() => {
			if (showKeywordGroupsDialog && defaultSettingsData) {
				if (isLoadingDialog) {
					setIsLoadingDialog(false);
				}
			}
		},
		[ showKeywordGroupsDialog, defaultSettingsData ]
	);

	const handleKeywordGroupItemMove = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = keywordGroups[dragIndex];
			const hoverItem = keywordGroups[hoverIndex];

			// Swap places of dragItem and hoverItem in the keywordGroups array
			setKeywordGroups((keywordGroups) => {
				const updatedKeywordGroups = [ ...keywordGroups ];
				updatedKeywordGroups[dragIndex] = hoverItem;
				updatedKeywordGroups[hoverIndex] = dragItem;

				//update index
				updatedKeywordGroups[hoverIndex].index = hoverIndex;
				updatedKeywordGroups[dragIndex].index = dragIndex;

				//update state
				updatedKeywordGroups[hoverIndex].state = 'updated';
				updatedKeywordGroups[dragIndex].state = 'updated';

				setKeywordGroupsItemsIndex(updatedKeywordGroups);

				return updatedKeywordGroups;
			});
		},
		[ keywords ]
	);

	const handleKeywordGroupItemRemove = useCallback(
		(index) => {
			if (window.confirm('Are you sure that you want to remove this keyword group?')) {
				setKeywordGroups((keywordGroups) => {
					const item = keywordGroups[index];
					const updatedKeywordGroups = [ ...keywordGroups ];

					if (item.state === 'added') {
						delete updatedKeywordGroups[index];
					} else {
						updatedKeywordGroups[index].state = 'removed';
					}

					setKeywordGroupsItemsIndex(updatedKeywordGroups);

					return updatedKeywordGroups;
				});
			}
		},
		[ keywordGroups ]
	);

	const setKeywordGroupsItemsIndex = (keywordGroupsArray) => {
		if (keywordGroupsArray && keywordGroupsArray.length) {
			for (let i = 0; i < keywordGroupsArray.length; i++) {
				keywordGroupsArray[i].index = i;
				keywordGroupsArray[i].orderIndex = i + 1;
			}
		}
	};

	const saveCampaign = async () => {
		let tempCampaignData = campaign;
		tempCampaignData.addNearMeInReports = appendNearMe;
		tempCampaignData.useNearInsteadOfIn = useNearInsteadOfIn;

		const response = await UpdateCampaign(campaignId, tempCampaignData);

		if (!response.success) {
			console.log(response.message);
		}
	};

	const handleSelectedKeywordsChange = async (e) => {
		const { name, checked } = e.target;
		let tempKeywordGroupsFormData = { ...keywordGroupsFormData };

		if (!tempKeywordGroupsFormData.keywords) {
			tempKeywordGroupsFormData.keywords = [];
		}

		if (checked) {
			let existingKw = keywords.find((x) => x.id === name.replace('chkKeyword_', ''));

			if (existingKw) {
				tempKeywordGroupsFormData.keywords.push(existingKw);
			}
		} else {
			tempKeywordGroupsFormData.keywords = tempKeywordGroupsFormData.keywords.filter(
				(x) => x.id !== name.replace('chkKeyword_', '')
			);
		}

		setKeywordGroupsFormData(tempKeywordGroupsFormData);
	};

	const isGroupKeywordSelected = (keywordId) => {
		if (keywordGroupsFormData && keywordGroupsFormData.id) {
			if (keywordGroupsFormData && keywordGroupsFormData.keywords) {
				for (const kw of keywordGroupsFormData.keywords) {
					if (kw.id === keywordId) {
						return true;
					}
				}
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs />

				<Fragment>
					<div className="campaign-form-container">
						<ul className="nav nav-tabs" id="tabKeywords" role="tablist">
							<li className="nav-item" role="presentation" key="keywords">
								<button
									className="nav-link active"
									id="keywords-tab"
									data-bs-toggle="tab"
									data-bs-target="#keywords-tab-pane"
									type="button"
									role="tab"
									aria-controls="keywords-tab-pane"
									aria-selected="true"
								>
									Keywords
								</button>
							</li>
							<li className="nav-item" role="presentation" key="groups">
								<button
									className="nav-link"
									id="groups-tab"
									data-bs-toggle="tab"
									data-bs-target="#groups-tab-pane"
									type="button"
									role="tab"
									aria-controls="groups-tab-pane"
									aria-selected="true"
								>
									Groups
								</button>
							</li>
						</ul>
						<div className="tab-content border" id="tabKeywordsContent">
							<div
								className="tab-pane fade p-3 show active"
								id="keywords-tab-pane"
								role="tabpanel"
								aria-labelledby="keywords-tab"
								tabIndex="0"
							>
								<div className="row">
									<div className="col-12">
										<button
											className="btn btn-primary mb-3"
											type="button"
											onClick={() => handleToggleKeywordsDialog()}
										>
											Add Keyword
										</button>
									</div>
								</div>

								{isLoading && <Spinner />}

								{!isLoading &&
								keywordsForList &&
								keywordsForList.length > 0 && (
									<Fragment>
										<div className="row mb-1">
											<div className="col-12">
												<table className="table border keywords-table">
													<thead>
														<tr>
															<th scope="col" className="index">
																<span>Index</span>
															</th>
															<th scope="col" className="keyword">
																<span>Keyword</span>
															</th>
															<th scope="col" className="headline-prompt">
																<span>Headline Prompt</span>
															</th>
															<th scope="col" className="content-prompt">
																<span>Content Prompt</span>
															</th>
															<th />
														</tr>
													</thead>
													<tbody>
														<DndProvider backend={HTML5Backend}>
															{keywordsForList.map((keyword, index) => {
																return (
																	keyword.state !== null &&
																	keyword.state !== 'removed' && (
																		<DndCard
																			index={index}
																			id={keyword.id ? keyword.id : ''}
																			isTableRow={true}
																			key={index}
																			moveCard={handleKeywordItemMove}
																			text={
																				<Fragment>
																					<td className="keyword">
																						{keyword.index + 1 || 0}
																					</td>
																					<td className="keyword">
																						{keyword.keyword}
																					</td>
																					<td
																						className="headline-prompt text-truncate"
																						title={keyword.headlinePrompt}
																					>
																						{keyword.headlinePrompt}
																					</td>
																					<td
																						className="content-prompt text-truncate"
																						title={keyword.contentPrompt}
																					>
																						{keyword.contentPrompt}
																					</td>
																					<td className="keyword-action text-end">
																						<button
																							href="#"
																							className="btn btn-link p-0"
																							onClick={() =>
																								handleToggleKeywordsDialog(
																									index
																								)}
																							title="Edit Keyword"
																						>
																							<i className="fa-solid fa-pen" />
																						</button>
																						<button
																							href="#"
																							className="btn btn-link p-0 ms-2"
																							onClick={() =>
																								handleKeywordItemRemove(
																									index
																								)}
																							title="Remove Keyword"
																						>
																							<i className="fa-solid fa-trash-can" />
																						</button>
																					</td>
																				</Fragment>
																			}
																		/>
																	)
																);
															})}
														</DndProvider>
													</tbody>
												</table>
											</div>
										</div>

										<div className="row mb-1">
											<div className="col-12">
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														id="addNearMeInReports"
														name="addNearMeInReports"
														onChange={(e) => setAppendNearMe(e.target.checked)}
														checked={appendNearMe}
													/>
													<label
														htmlFor="addNearMeInReports"
														className="form-check-label w-auto"
													>
														Append "near me" when searching
													</label>
												</div>
											</div>
										</div>

										<div className="row mb-1">
											<div className="col-12">
												<div className="form-check">
													<input
														type="checkbox"
														className="form-check-input"
														id="useNearInsteadOfIn"
														name="useNearInsteadOfIn"
														onChange={(e) => setUseNearInsteadOfIn(e.target.checked)}
														checked={useNearInsteadOfIn}
													/>
													<label
														htmlFor="useNearInsteadOfIn"
														className="form-check-label w-auto"
													>
														Use "near" instead of "in"
													</label>
												</div>
											</div>
										</div>
									</Fragment>
								)}
							</div>
							<div
								className="tab-pane fade p-3"
								id="groups-tab-pane"
								role="tabpanel"
								aria-labelledby="groups-tab"
								tabIndex="0"
							>
								<div className="row">
									<div className="col-12">
										<button
											className="btn btn-primary mb-3"
											type="button"
											onClick={() => handleToggleKeywordGroupsDialog()}
										>
											Add Group
										</button>
									</div>
								</div>

								{isLoading && <Spinner />}

								<div className="row mb-1">
									<div className="col-12">
										<table className="table border keywords-table">
											<thead>
												<tr>
													<th scope="col" className="index">
														<span>Index</span>
													</th>
													<th scope="col" className="keyword">
														<span>Name</span>
													</th>
													<th scope="col" className="headline-prompt">
														<span>Headline Prompt</span>
													</th>
													{/* <th scope="col" className="content-prompt">
																<span>Content Prompt</span>
															</th> */}
													<th />
												</tr>
											</thead>
											{!isLoading &&
											keywordGroupsForList &&
											keywordGroupsForList.length > 0 && (
												<Fragment>
													<tbody>
														<DndProvider backend={HTML5Backend}>
															{keywordGroupsForList.map((keywordGroup, index) => {
																return (
																	keywordGroup.state !== null &&
																	keywordGroup.state !== 'removed' && (
																		<DndCard
																			index={index}
																			id={keywordGroup.id ? keywordGroup.id : ''}
																			isTableRow={true}
																			key={index}
																			moveCard={handleKeywordGroupItemMove}
																			text={
																				<Fragment>
																					<td className="keyword">
																						{keywordGroup.index + 1 || 0}
																					</td>
																					<td className="keyword">
																						{keywordGroup.name}
																					</td>
																					<td
																						className="headline-prompt text-truncate"
																						title={
																							keywordGroup.groupHeadlinePrompt
																						}
																					>
																						{
																							keywordGroup.groupHeadlinePrompt
																						}
																					</td>
																					{/* <td
																						className="content-prompt text-truncate"
																						title={
																							keywordGroup.contentPrompt
																						}
																					>
																						{keywordGroup.contentPrompt}
																					</td> */}
																					<td className="keyword-action text-end">
																						<button
																							href="#"
																							className="btn btn-link p-0"
																							onClick={() =>
																								handleToggleKeywordGroupsDialog(
																									index
																								)}
																							title="Edit Keyword Group"
																						>
																							<i className="fa-solid fa-pen" />
																						</button>
																						<button
																							href="#"
																							className="btn btn-link p-0 ms-2"
																							onClick={() =>
																								handleKeywordGroupItemRemove(
																									index
																								)}
																							title="Remove Keyword Group"
																						>
																							<i className="fa-solid fa-trash-can" />
																						</button>
																					</td>
																				</Fragment>
																			}
																		/>
																	)
																);
															})}
														</DndProvider>
													</tbody>
												</Fragment>
											)}
										</table>
									</div>
								</div>
							</div>
						</div>

						{showKeywordsDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									{isContentTest ? (
										<Fragment>
											{isGeneratingContentTest ? (
												<Spinner />
											) : (
												<Fragment>
													<div className="row">
														<div className="col-12">
															<h5 className="mb-3">{`Result for keyword "${keywordsFormData.keyword}"`}</h5>
														</div>
													</div>
													<div className="row">
														<div
															//ref={contentTestResponseRef}
															className="col-12"
															dangerouslySetInnerHTML={{ __html: contentTestResponse }}
														/>
													</div>
												</Fragment>
											)}
										</Fragment>
									) : (
										<Fragment>
											{isLoadingDialog ? (
												<Spinner />
											) : (
												<form className="row">
													<div className="col-12 mb-3">
														<label htmlFor="keyword" className="form-label">
															Keyword:
														</label>
														<input
															type="text"
															className="form-control"
															name="keyword"
															value={keywordsFormData.keyword || ''}
															onChange={handleKeywordsFormDataChange}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														{keywordsFormData.keyword && (
															<div className="form-controls-additional">
																<a
																	className="btn btn-link p-0"
																	href={`https://www.google.com/search?q=${keywordsFormData.keyword}`}
																	target="_blank"
																>
																	Test
																</a>
															</div>
														)}
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="headlinePrompt" className="form-label">
															Headline Prompt:
														</label>
														<textarea
															className="form-control"
															name="headlinePrompt"
															value={keywordsFormData.headlinePrompt || ''}
															onChange={handleKeywordsFormDataChange}
															rows={4}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														<div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Settings
															</button>
														</div>
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="contentPrompt" className="form-label">
															Content Prompt:
														</label>
														<textarea
															className="form-control"
															name="contentPrompt"
															value={keywordsFormData.contentPrompt || ''}
															onChange={handleKeywordsFormDataChange}
															rows={4}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														<div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(true)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Settings
															</button>
														</div>
													</div>
													<div className="col-12 mb-3">
														<label
															htmlFor="keywordPostHeadlinePrompt"
															className="form-label"
														>
															Keyword Post Headline Prompt:
														</label>
														<textarea
															className="form-control"
															name="keywordPostHeadlinePrompt"
															value={keywordsFormData.keywordPostHeadlinePrompt || ''}
															onChange={handleKeywordsFormDataChange}
															rows={4}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														<div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(true)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Settings
															</button>
														</div>
													</div>
													<div className="col-12 mb-3">
														<label
															htmlFor="keywordPostContentPrompt"
															className="form-label"
														>
															Keyword Post Content Prompt:
														</label>
														<textarea
															className="form-control"
															name="keywordPostContentPrompt"
															value={keywordsFormData.keywordPostContentPrompt || ''}
															onChange={handleKeywordsFormDataChange}
															rows={4}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														<div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(true)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Settings
															</button>
														</div>
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="keywordItems" className="form-label">
															Keyword Items:
														</label>
														<textarea
															className="form-control"
															name="keywordItems"
															value={keywordsFormData.keywordItems || ''}
															onChange={handleKeywordsFormDataChange}
															rows={6}
															required
															disabled={isSubmitting || isGettingKeywordItems}
															wrap="off"
														/>
														<div>
															<button
																className="btn btn-link ps-0"
																type="button"
																onClick={(e) =>
																	getKeywordItems(e, keywordsFormData.keyword)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																{isGettingKeywordItems ? (
																	<Fragment>
																		<span className="spinner-border m-0 me-2" />
																		<span>Getting Keywords...</span>
																	</Fragment>
																) : (
																	<span>Get Keywords</span>
																)}
															</button>
														</div>
													</div>
													<div className="col-12">
														<label htmlFor="keywordVariations" className="form-label">
															Keyword Variations:
														</label>
														<input
															type="text"
															className="form-control"
															name="keywordVariations"
															value={keywordsFormData.keywordVariations || ''}
															onChange={handleKeywordsFormDataChange}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
													</div>
												</form>
											)}
										</Fragment>
									)}
								</ModalDialogContent>
								<ModalDialogButtons>
									{isContentTest ? (
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={toggleTestContentResponse}
										>
											Close
										</button>
									) : (
										<Fragment>
											<button
												className="btn btn-primary"
												type="button"
												onClick={handleSaveKeywordItem}
											>
												{isSubmitting ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Saving</span>
													</Fragment>
												) : (
													<span>Save</span>
												)}
											</button>
											<button
												className="btn btn-outline-primary ms-2"
												type="button"
												onClick={handleToggleKeywordsDialog}
												disabled={isSubmitting || isGettingKeywordItems}
											>
												Cancel
											</button>
										</Fragment>
									)}
								</ModalDialogButtons>
							</ModalDialog>
						)}

						{showKeywordGroupsDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									{isContentTest ? (
										<Fragment>
											{isGeneratingContentTest ? (
												<Spinner />
											) : (
												<Fragment>
													<div className="row">
														<div className="col-12">
															<h5 className="mb-3">{`Result for keyword "${keywordGroupsFormData.name}"`}</h5>
														</div>
													</div>
													<div className="row">
														<div
															className="col-12"
															dangerouslySetInnerHTML={{ __html: contentTestResponse }}
														/>
													</div>
												</Fragment>
											)}
										</Fragment>
									) : (
										<Fragment>
											{isLoadingDialog ? (
												<Spinner />
											) : (
												<form className="row">
													<div className="col-12 mb-3">
														<label htmlFor="name" className="form-label">
															Group Name:
														</label>
														<input
															type="text"
															className="form-control"
															name="name"
															value={keywordGroupsFormData.name || ''}
															onChange={handleKeywordGroupsFormDataChange}
															disabled={isSubmitting}
														/>
														{keywordGroupsFormData.name && (
															<div className="form-controls-additional">
																<a
																	className="btn btn-link p-0"
																	href={`https://www.google.com/search?q=${keywordGroupsFormData.name}`}
																	target="_blank"
																>
																	Test
																</a>
															</div>
														)}
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="groupHeadlinePrompt" className="form-label">
															Group Headline Prompt:
														</label>
														<textarea
															className="form-control"
															name="groupHeadlinePrompt"
															value={keywordGroupsFormData.groupHeadlinePrompt || ''}
															onChange={handleKeywordGroupsFormDataChange}
															rows={4}
															disabled={isSubmitting}
														/>
														{/* <div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(false)}
																disabled={isSubmitting}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting}
															>
																Settings
															</button>
														</div> */}
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="groupLocationPrompt" className="form-label">
															Group Location Prompt:
														</label>
														<textarea
															className="form-control"
															name="groupLocationPrompt"
															value={keywordGroupsFormData.groupLocationPrompt || ''}
															onChange={handleKeywordGroupsFormDataChange}
															rows={4}
															disabled={isSubmitting}
														/>
														{/* <div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(false)}
																disabled={isSubmitting}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting}
															>
																Settings
															</button>
														</div> */}
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="keywords" className="form-label">
															Keywords:
														</label>
														<table className="table border keywords-table">
															{keywords && (
																<tbody>
																	{keywords.map((keyword, index) => {
																		let isKeywordSelected = isGroupKeywordSelected(
																			keyword.id
																		);

																		return (
																			<tr key={keyword.id}>
																				<td className="checkbox-input">
																					<input
																						type="checkbox"
																						className="form-check-input"
																						name={`chkKeyword_${keyword.id}`}
																						id={`chkKeyword_${keyword.id}`}
																						onChange={
																							handleSelectedKeywordsChange
																						}
																						checked={isKeywordSelected}
																					/>
																				</td>
																				<td>{keyword.keyword}</td>
																			</tr>
																		);
																	})}
																</tbody>
															)}
														</table>
													</div>
												</form>
											)}
										</Fragment>
									)}
								</ModalDialogContent>
								<ModalDialogButtons>
									{isContentTest ? (
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={toggleTestContentResponse}
										>
											Close
										</button>
									) : (
										<Fragment>
											<button
												className="btn btn-primary"
												type="button"
												onClick={handleSaveKeywordGroupItem}
											>
												{isSubmitting ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Saving</span>
													</Fragment>
												) : (
													<span>Save</span>
												)}
											</button>
											<button
												className="btn btn-outline-primary ms-2"
												type="button"
												onClick={handleToggleKeywordGroupsDialog}
												disabled={isSubmitting}
											>
												Cancel
											</button>
										</Fragment>
									)}
								</ModalDialogButtons>
							</ModalDialog>
						)}

						{showDefaultSettingsDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									<h4 className="mb-3">Default Settings</h4>
									<form className="row">
										<div className="col-12 mb-3">
											<label htmlFor="headlineInstructionsAI" className="form-label">
												Headline Instructions:
											</label>
											<input
												type="text"
												className="form-control"
												name="headlineInstructionsAI"
												value={defaultSettingsData.headlineInstructionsAI || ''}
												onChange={handleDefaultSettingsChange}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="descriptionInstructionsAI" className="form-label">
												Description Instructions:
											</label>
											<textarea
												className="form-control"
												name="descriptionInstructionsAI"
												value={defaultSettingsData.descriptionInstructionsAI || ''}
												onChange={handleDefaultSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordsHeadlinePrompt" className="form-label">
												Keywords Headline Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordsHeadlinePrompt"
												value={defaultSettingsData.keywordsHeadlinePrompt || ''}
												onChange={handleDefaultSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12">
											<label htmlFor="keywordsContentPrompt" className="form-label">
												Keywords Content Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordsContentPrompt"
												value={defaultSettingsData.keywordsContentPrompt || ''}
												onChange={handleDefaultSettingsChange}
												rows={3}
											/>
										</div>
									</form>
								</ModalDialogContent>
								<ModalDialogButtons>
									<button className="btn btn-primary" onClick={handleSaveDefaultSettings}>
										<span>Save</span>
									</button>
									<button
										className="btn btn-outline-primary"
										onClick={handleToggleDefaultSettingsDialog}
									>
										<span>Cancel</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						)}
					</div>

					<CampaignButtonsContainer
						campaign={campaign}
						onSave={() => {
							handleKeywordsSubmit();
							handleKeywordGroupsSubmit();
						}}
						onSaveNext={() => {
							handleKeywordsSubmit(true);
							handleKeywordGroupsSubmit(true);
						}}
						disabled={isSubmitting || isGettingKeywordItems}
					/>
				</Fragment>
			</div>
		</Fragment>
	);
};

export default CampaignKeywordsScreen;
