import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	GenerateCampaignAIContent,
	GetCampaign,
	GetCampaignDefaultSettings,
	UpdateCampaign,
	UpdateCampaignDefaultSettings,
	UpdateCampaignStatus
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignHeadlineDescScreen.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { replaceMergedFieldsInDefaultSettings } from '../../../utils/Common';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';

const CampaignHeadlineDescScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ settingsFormData, setSettingsFormData ] = useState({});
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const [ isGeneratingHeadlineAI, setIsGeneratingHeadlineAI ] = useState(false);
	const [ isGeneratingDescAI, setIsGeneratingDescAI ] = useState(false);
	const [ showDialogHeadlineAI, setShowDialogHeadlineAI ] = useState(false);
	const [ showDialogDescAI, setShowDialogDescAI ] = useState(false);
	const [ aiHeadlines, setAiHeadlines ] = useState([]);
	const [ headlineInstructionsAI, setHeadlineInstructionsAI ] = useState('');
	const [ descInstructionsAI, setDescInstructionsAI ] = useState('');
	const [ descResponseAI, setDescResponseAI ] = useState('');
	const [ showDefaultSettingsDialog, setShowDefaultSettingsDialog ] = useState(false);
	const [ tempHeadlineAI, setTempHeadlineAI ] = useState('');
	const [ tempDescAI, setTempDescAI ] = useState('');
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		setIsLoading(true);

		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
			setFormData(response);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});

		if (e.target.name === 'aiHeadline') {
			setTempHeadlineAI(e.target.value);
		} else if (e.target.name === 'descResponseAI') {
			setDescResponseAI(e.target.value);
			setTempDescAI(e.target.value);
		}
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		const response = await UpdateCampaign(campaignId, formData);

		if (response.success) {
			if (redirect) {
				navigate(`/setup/${campaignId}/cta`);
			} else {
				toast.success('Settings saved.');
			}
		}
	};

	const handleToggleDialogHeadlineAI = async (e) => {
		e.preventDefault();

		setShowDialogHeadlineAI(!showDialogHeadlineAI);

		if (!showDialogHeadlineAI) {
			var defaultSettings = await GetCampaignDefaultSettings();

			if (defaultSettings && defaultSettings.data && defaultSettings.data.headlineInstructionsAI) {
				// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);
				// setHeadlineInstructionsAI(settings.headlineInstructionsAI);

				setHeadlineInstructionsAI(defaultSettings.data.headlineInstructionsAI);
			}
		}
	};

	const generateHeadlinesAI = async (e) => {
		e.preventDefault();

		// if (!formData.listingType || formData.listingType.trim() === '') {
		// 	toast.error('You have to set industry field first.');
		// 	return;
		// }

		setIsGeneratingHeadlineAI(true);

		let data = {
			instructions : headlineInstructionsAI
		};

		const response = await GenerateCampaignAIContent(campaign.id, data);

		if (response.success && response.data) {
			let headlinesArray = response.data.split('<br>');

			if (headlinesArray && headlinesArray.length > 0) {
				//remove numbers from response items
				for (let i = 0; i < headlinesArray.length; i++) {
					headlinesArray[i] = headlinesArray[i].replace(i + 1 + '. ', '');
				}

				setAiHeadlines(headlinesArray);
				setTempHeadlineAI(headlinesArray[0]);
			}
		}

		setIsGeneratingHeadlineAI(false);
	};

	const handleToggleDialogDescriptionAI = async (e) => {
		e.preventDefault();

		setShowDialogDescAI(!showDialogDescAI);

		if (!showDialogDescAI) {
			var defaultSettings = await GetCampaignDefaultSettings();

			if (defaultSettings && defaultSettings.data && defaultSettings.data.descriptionInstructionsAI) {
				// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);
				// setDescInstructionsAI(settings.descriptionInstructionsAI);

				setDescInstructionsAI(defaultSettings.data.descriptionInstructionsAI);
			}
		}
	};

	const generateDescriptionAI = async (e) => {
		e.preventDefault();

		// if (!formData.listingType || formData.listingType.trim() === '') {
		// 	toast.error('You have to set industry field first.');
		// 	return;
		// }

		setIsGeneratingDescAI(true);

		let data = {
			instructions : descInstructionsAI
		};

		const response = await GenerateCampaignAIContent(campaign.id, data);

		if (response.success && response.data) {
			setDescResponseAI(response.data);
			setTempDescAI(response.data);
		}

		setIsGeneratingDescAI(false);
	};

	const handleToggleDefaultSettingsDialog = async (e) => {
		if (e) {
			e.preventDefault();
		}

		setShowDefaultSettingsDialog(!showDefaultSettingsDialog);

		if (!showDefaultSettingsDialog) {
			let response = await GetCampaignDefaultSettings();

			if (response && response.data) {
				setSettingsFormData(response.data);
			}
		}
	};

	const handleSaveDefaultSettings = async () => {
		if (settingsFormData) {
			console.log(settingsFormData);

			let response = await UpdateCampaignDefaultSettings(settingsFormData);

			if (response) {
				handleToggleDefaultSettingsDialog();
				toast.success('Default settings updated successfully.');
			} else {
				toast.error(response.message);
			}
		}
	};

	const handleSettingsChange = async (e) => {
		setSettingsFormData({
			...settingsFormData,
			[e.target.name]: e.target.value
		});
	};

	const saveHeadlineAIDialog = (e) => {
		e.preventDefault();

		if (tempHeadlineAI) {
			setFormData({
				...formData,
				headline : tempHeadlineAI
			});
		}

		setShowDialogHeadlineAI(false);
		setTempHeadlineAI('');
	};

	const cancelHeadlineAIDialog = (e) => {
		e.preventDefault();

		setShowDialogHeadlineAI(false);
		setTempHeadlineAI('');
	};

	const saveDescAIDialog = (e) => {
		e.preventDefault();

		if (tempDescAI) {
			setFormData({
				...formData,
				description : tempDescAI
			});
		}

		setShowDialogDescAI(false);
		setTempDescAI('');
	};

	const cancelDescAIDialog = (e) => {
		e.preventDefault();

		setShowDialogDescAI(false);
		setTempDescAI('');
	};

	return (
		<div className="container-fluid campaign-main-container">
			<Fragment>
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>
				<CampaignTabs />

				<div className="campaign-form-container no-overflow">
					<CampaignSubTabs />

					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="campaign-subform-container">
								<form className="text-start">
									<div className="row mb-4">
										<label htmlFor="headline" className="form-label col-1">
											Home Headline:
										</label>
										<div className="col-5">
											<textarea
												className="form-control"
												name="headline"
												value={formData.headline || ''}
												onChange={handleChange}
												rows={3}
											/>
											<div className="form-controls-additional">
												<button
													className="btn btn-link p-0"
													type="button"
													onClick={handleToggleDialogHeadlineAI}
												>
													<span>Generate</span>
												</button>
											</div>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="description" className="form-label col-1">
											Home Description:
										</label>
										<div className="col-5">
											<textarea
												className="form-control"
												name="description"
												value={formData.description || ''}
												onChange={handleChange}
												rows={8}
											/>
											<div className="form-controls-additional">
												<button
													className="btn btn-link p-0"
													type="button"
													onClick={handleToggleDialogDescriptionAI}
												>
													<span>Generate</span>
												</button>
											</div>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="contentInclusions" className="form-label col-1">
											Inclusions:
										</label>
										<div className="col-5">
											<textarea
												className="form-control"
												name="contentInclusions"
												value={formData.contentInclusions || ''}
												onChange={handleChange}
												rows={3}
											/>
										</div>
									</div>
									<div className="row">
										<label htmlFor="contentExclusions" className="form-label col-1">
											Exclusions:
										</label>
										<div className="col-5">
											<textarea
												className="form-control"
												name="contentExclusions"
												value={formData.contentExclusions || ''}
												onChange={handleChange}
												rows={3}
											/>
										</div>
									</div>
								</form>
							</div>

							{showDialogHeadlineAI && (
								<ModalDialog position="right-sidebar">
									<ModalDialogContent align="start">
										{/* <h4 className="mb-3">Generate Headline</h4> */}
										<form className="row">
											<div className="col-12 mb-4">
												<label htmlFor="headlineInstructionsAI" className="form-label">
													Command:
												</label>
												<textarea
													className="form-control"
													name="headlineInstructionsAI"
													value={headlineInstructionsAI || ''}
													onChange={(e) => setHeadlineInstructionsAI(e.target.value)}
													rows={5}
													disabled={isGeneratingHeadlineAI}
												/>
												<div className="form-controls-additional">
													<button
														className="btn btn-link p-0"
														onClick={generateHeadlinesAI}
														disabled={isGeneratingHeadlineAI}
													>
														{isGeneratingHeadlineAI ? (
															<Fragment>
																<span className="spinner-border m-0 me-2" />
																<span>Generating</span>
															</Fragment>
														) : (
															<span>Generate</span>
														)}
													</button>
													<span className="link-separator mx-1">|</span>
													<button
														className="btn btn-link p-0"
														onClick={handleToggleDefaultSettingsDialog}
														disabled={isGeneratingHeadlineAI}
													>
														<span>Settings</span>
													</button>
												</div>
											</div>

											<div className="col-12">
												<label htmlFor="aiHeadline" className="form-label">
													Response:
												</label>
												<select
													className="form-select"
													name="aiHeadline"
													onChange={handleChange}
													disabled={isGeneratingHeadlineAI}
												>
													{aiHeadlines &&
														aiHeadlines.length > 0 &&
														aiHeadlines.map((aiHeadline, index) => (
															<option key={index} value={aiHeadline}>
																{aiHeadline}
															</option>
														))}
												</select>
											</div>
										</form>
									</ModalDialogContent>
									<ModalDialogButtons shaded align="start">
										<button
											className="btn btn-primary"
											onClick={saveHeadlineAIDialog}
											disabled={isGeneratingHeadlineAI}
										>
											<span>Save</span>
										</button>
										<button
											className="btn btn-outline-primary"
											onClick={cancelHeadlineAIDialog}
											disabled={isGeneratingHeadlineAI}
										>
											<span>Cancel</span>
										</button>
									</ModalDialogButtons>
								</ModalDialog>
							)}

							{showDialogDescAI && (
								<ModalDialog position="right-sidebar">
									<ModalDialogContent align="start">
										{/* <h4 className="mb-3">Generate Description</h4> */}
										<form className="row">
											<div className="col-12 mb-4">
												<label htmlFor="descInstructionsAI" className="form-label">
													Command:
												</label>
												<textarea
													className="form-control"
													name="descInstructionsAI"
													value={descInstructionsAI || ''}
													onChange={(e) => setDescInstructionsAI(e.target.value)}
													rows={5}
													disabled={isGeneratingDescAI}
												/>
												<div className="form-controls-additional">
													<button
														className="btn btn-link p-0"
														onClick={generateDescriptionAI}
														disabled={isGeneratingDescAI}
													>
														{isGeneratingDescAI ? (
															<Fragment>
																<span
																	className="spinner-border m-0 me-2"
																	role="status"
																	aria-hidden="true"
																/>
																<span>Generating</span>
															</Fragment>
														) : (
															<span>Generate</span>
														)}
													</button>
													<span className="link-separator mx-1">|</span>
													<button
														className="btn btn-link p-0"
														onClick={handleToggleDefaultSettingsDialog}
														disabled={isGeneratingDescAI}
													>
														<span>Settings</span>
													</button>
												</div>
											</div>

											<div className="col-12">
												<label htmlFor="descResponseAI" className="form-label">
													Response:
												</label>
												<textarea
													className="form-control"
													name="descResponseAI"
													value={descResponseAI || ''}
													onChange={handleChange}
													rows={8}
													disabled={isGeneratingDescAI}
												/>
											</div>
										</form>
									</ModalDialogContent>
									<ModalDialogButtons shaded align="start">
										<button
											className="btn btn-primary"
											onClick={saveDescAIDialog}
											disabled={isGeneratingDescAI}
										>
											<span>Save</span>
										</button>
										<button
											className="btn btn-outline-primary"
											onClick={cancelDescAIDialog}
											disabled={isGeneratingDescAI}
										>
											<span>Cancel</span>
										</button>
									</ModalDialogButtons>
								</ModalDialog>
							)}

							{showDefaultSettingsDialog && (
								<ModalDialog position="right-sidebar">
									<ModalDialogContent align="start">
										<h4 className="mb-3">Default Settings</h4>
										<form className="row">
											<div className="col-12 mb-3">
												<label htmlFor="headlineInstructionsAI" className="form-label">
													Headline Instructions:
												</label>
												<input
													type="text"
													className="form-control"
													name="headlineInstructionsAI"
													value={settingsFormData.headlineInstructionsAI || ''}
													onChange={handleSettingsChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="descriptionInstructionsAI" className="form-label">
													Description Instructions:
												</label>
												<textarea
													className="form-control"
													name="descriptionInstructionsAI"
													value={settingsFormData.descriptionInstructionsAI || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordsHeadlinePrompt" className="form-label">
													Keywords Headline Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordsHeadlinePrompt"
													value={settingsFormData.keywordsHeadlinePrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12">
												<label htmlFor="keywordsContentPrompt" className="form-label">
													Keywords Content Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordsContentPrompt"
													value={settingsFormData.keywordsContentPrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
										</form>
									</ModalDialogContent>
									<ModalDialogButtons>
										<button className="btn btn-primary" onClick={handleSaveDefaultSettings}>
											<span>Save</span>
										</button>
										<button
											className="btn btn-outline-primary"
											onClick={handleToggleDefaultSettingsDialog}
										>
											<span>Close</span>
										</button>
									</ModalDialogButtons>
								</ModalDialog>
							)}
						</Fragment>
					)}
				</div>
				<CampaignButtonsContainer
					campaign={campaign}
					onSave={handleSubmit}
					onSaveNext={() => handleSubmit(true)}
				/>
			</Fragment>
		</div>
	);
};

export default CampaignHeadlineDescScreen;
